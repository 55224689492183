const PATHS = {
    DEFAULT: "/",
    LOGIN: "/login",
    SET_PASSWORD: "/setPassword",
    SELECT_BETSHOP: "/selectBetShop",
    HANDOVER: "/handover",
    CASHIER: "/cashier",
    MANAGER: "/manager",
    MANAGER_DASHBOARD: "/manager/dashboard",
    MANAGER_BETSHOP_EDIT: "/manager/betshopEdit",
    MANAGER_REPORTS: "/manager/reports",
    MANAGER_SESSIONS: "/manager/sessions",
    MANAGER_BET_HISTORY: "/manager/betHistory",
    MANAGER_VOUCHER_HISTORY: "/manager/voucherHistory",
    MANAGER_BETSHOP_TRANSACTIONS: "/manager/betshopTransactions",
    MANAGER_CASHIER_TRANSACTIONS: "/manager/cashierTransactions",
    MANAGER_BETSHOP_PERFORMANCE_REPORT: "/manager/betshopPerformanceReport",
    MANAGER_CASHIER_PERFORMANCE_REPORT: "/manager/cashierPerformanceReport",
    MIRROR:"/mirror"
}

export default PATHS;
