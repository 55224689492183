import React from 'react';

// import VoucherHistoryComponent from 'components/common/voucherHistory';

import withAuth from 'hocs/withAuth';
import VoucherHistory from "components/common/voucherHistory";
import {connect} from "react-redux";
import {isTerminalProject} from "utils/access";
import {Navigate} from "react-router-dom";
import Paths from "constants/path.constants";

const ManagerVoucherHistoryRoute = ({ userInfo }) => {
    if (!isTerminalProject(userInfo.projectType)) {
        return <Navigate to={`/${Paths.MANAGER}`} />
    }

    return (
        <VoucherHistory />
    )
}

const mapStateToProps = state => {
    return {
        userInfo: state.profile.userInfo
    }
}


export default withAuth(connect(mapStateToProps)(ManagerVoucherHistoryRoute));
