import React from 'react';

import LanguageUtils from 'utils/languages';

import {
    changeRGBAOpacityToGivenNumber,
    customizeIndicationLine,
    hasAtLeastOneNonZeroValue,
    validateRGBA,
    yAxisTextDefaultFormatter,
} from "./helpers";

import {
    LINE_CHART_AVAILABLE_COLOR,
    MARKET_SYMBOL_TYPES
} from "constants/lineChart.constants";


const currentLn = LanguageUtils.getSelectedLanguage();

//#region ------------------------------------- X AXIS CONFIGS ----------------------------------------//

export const getXAxisConfigs = ({
    textColor = LINE_CHART_AVAILABLE_COLOR.GRAY,
    fontSize = 12,
    checkPoints = [],
} = {}) => {
    return {
        title: {
            text: null
        },
        min: 0.40,
        reversed: false,
        max: checkPoints.length - 1.4,
        categories: checkPoints,
        labels: {

            style: {
                color: textColor,
                fontSize: fontSize,
            },
        },
    }
}

//#endregion

//#region ------------------------------------- Y AXIS CONFIGS ----------------------------------------//

export const getYAxisConfigs = ({
    textColor = LINE_CHART_AVAILABLE_COLOR.GRAY,
    fontSize = 12,
    horizontalLinesColor = LINE_CHART_AVAILABLE_COLOR.LIGHT_GREY,
    textFormatter = yAxisTextDefaultFormatter,
} = {}) => {
    return {
        title: {
            text: null
        },
        gridLineColor: horizontalLinesColor,
        opposite: false,
        labels: {
            style: {
                color: textColor,
                fontSize: fontSize,
            },
            formatter: function () {
                const value = this.value;

                return textFormatter(value);
            }
        },
    }
}

//#endregion

//#region ------------------------------------- LEGEND CONFIGS ----------------------------------------//

export const getLegendConfigs = ({
    horizontalAlignment = "left",
    verticalAlignment = "top",
} = {}) => {
    return {
        legend: {
            enabled: true,
            align: horizontalAlignment,
            verticalAlign: verticalAlignment,
            x: -18,
            y: -16,
            margin: 30,
            useHTML: true,
            symbolWidth: 0,
            labelFormatter: function() {
                const symbol = `
                    <div
                        class="rt--highchart-legend"
                        style="width: 10px; height: 10px; border-radius: 50%; margin-right: 4px; margin-top: 1px; background-color: ${this.color == 'transparent' ? '#80808066' : this.color}"
                    >
                    </div>
                `

                return `
                    <div
                        style="display:flex; font-size: 12px; color: rgb(51, 51, 51); font-weight: 500"
                    >
                        ${symbol} ${this.name}
                    </div>
                `
            }
        }
    };
}

//#endregion

//#region ------------------------------- CHECKPOINTS TOOLTIP CONFIGS ---------------------------------//

// tooltipHTMLGenerator should return HTML(not react component) for render tooltip on checkpoints hover
export const getCheckPointsTooltipConfigs = ({ tooltipHTMLGenerator }) => {
    return {
        useHTML: true,
        borderWidth: 0,
        backgroundColor: "transparent",
        shadow: false,
        outside: true,
        formatter: function () {
            return (
                typeof tooltipHTMLGenerator === "function"
                    ? tooltipHTMLGenerator(this)
                    : <></>
            )

        }
    }
}

//#endregion

//#region ------------------------------------ MAIN LINE CONFIGS --------------------------------------//

export const getMainLineConfigs = ({
    data = [],
    name = "Line",

    // Main Line Configs
    mainLineColor = LINE_CHART_AVAILABLE_COLOR.LIGHT_BLUE,
    mainLineWidth = 7,
    mainLineWidthOnHover = 7,

    // Indication Symbol Configs
    indicationSymbolType = MARKET_SYMBOL_TYPES.CIRCLE,
    indicationSymbolColor = LINE_CHART_AVAILABLE_COLOR.WHITE,
    indicationSymbolBorderColor = mainLineColor,
    indicationSymbolBorderWidth = 3,

    // Indication Line Configs
    indicationLineColor = mainLineColor,

    // Line Shadow Configs
    lineShadowColor = mainLineColor,

    // Line Area Shadow Configs
    lineAreaShadowColor = mainLineColor,

    showMarkersOnlyOnHover = true,

} = {}) => {
    if (!validateRGBA(mainLineColor)) {
        console.error(new Error("mainLineColor must be in 'rgba' format only, please fix it, otherwise the function will not work."));

        return {};
    }

    return {
        data,
        name,
        color: hasAtLeastOneNonZeroValue(data) ? mainLineColor : "transparent", // Do not display the main line if the data is zero
        lineWidth: mainLineWidth,
        marker: {
            enabled: !showMarkersOnlyOnHover,
            symbol: indicationSymbolType,
            lineColor: indicationSymbolBorderColor,
            lineWidth: indicationSymbolBorderWidth,
            states: {
                hover: {
                    fillColor: indicationSymbolColor,
                    lineColor: indicationSymbolBorderColor,
                    lineWidth: indicationSymbolBorderWidth
                }
            }
        },
        boostThreshold: 1,
        point: {
            events: {
                mouseOver: function () {
                    customizeIndicationLine({
                        element: this,
                        color: indicationLineColor
                    });
                },
            }
        },

        states: {
            hover: {
                lineWidth: mainLineWidthOnHover,
            }
        },
        shadow: {
            color: lineShadowColor,
            width: 18,
            opacity: 0.1,
            offsetX: 0,
            offsetY: 10
        },
        fillColor: {
            pattern: {
                path: {
                    d: 'M 4 10 L 2 10 M 9 - 1 L 11 1 M - 1 9',
                    stroke: mainLineColor,
                    strokeWidth: 3,
                },
                width: 10,
                height: 10,
            }
        }
    }
}

//#endregion
