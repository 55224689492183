import React from 'react';

import noDataImg from "assets/images/noContent.png";
import {useTranslation} from "react-i18next";

const LaunchError = () => {
    const { t } = useTranslation();

    return (
        <div className="rt--launch-error">
            <img alt="noDataImg" className="rt--mb-16" src={noDataImg} />

            <span className="rt--launch-error-title rt--mb-8 rt--font-biger">{t("common.launchErrorTitle")}</span>

            <span className="rt--launch-error-description">{t("common.launchErrorMessage")}</span>
        </div>
    );
};

export default LaunchError;
