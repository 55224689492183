import {useDashboardContext} from "components/manager/dashboard/contexts/dashboardContext";
import {useMemo} from "react";
import {Col, Row, Tabs} from "antd";
import PieChart from "components/manager/dashboard/components/pieChart";
import LeaderboardFilters from "components/manager/dashboard/tabs/usersTab/filters/laederbord";
// import LeaderboardFilters from "components/manager/dashboard/tabs/usersTab/filters/laederbord";


const UsersTabDesktop = ({
    onFiltersChange,
    updateLastUpdatedDate,
    currencyOptions,
    leaderboardFilters,
    pieChartFilters,
    items,
    pieChartItems,
    onTabChange,
    dataTypeOptions
}) => {
    const { state: { leaderboardTab } } = useDashboardContext();

    const handleFiltersChange = (value) => {
        onFiltersChange(value);
    }

    const handleTabChange = (key) => {
        onTabChange(key);
    }

    const pieCharts = useMemo(() => {
        return (
            <Row gutter={[16, 16]} className="rt--mb-24">
                {pieChartItems.map(chart => (
                    <Col key={chart.id} span={Math.floor(24 / pieChartItems.length)}>
                        <PieChart
                            entityType={chart.entityType}
                            filters={pieChartFilters}
                            updateLastUpdatedDate={updateLastUpdatedDate}
                        />
                    </Col>
                ))}
            </Row>
        );
    }, [pieChartFilters, pieChartItems, updateLastUpdatedDate]);

    return (
        <div className="rt--dashboard-users">
            {pieCharts}

            <div>
                <LeaderboardFilters
                    filters={leaderboardFilters}
                    onChange={handleFiltersChange}
                    dataTypeOptions={dataTypeOptions}
                    currencyOptions={currencyOptions}
                />

                <div className="rt--chart-section rt--dashboard-users-leaderboard">
                    <Tabs
                        activeKeys={[leaderboardTab]}
                        animated={false}
                        destroyInactiveTabPane={true}
                        className='rt--tabs rt--chart-tab'
                        onChange={handleTabChange}
                    >
                        {items.map(item => (
                            <Tabs.TabPane key={item.key} tab={item.label}>
                                {item.children}
                            </Tabs.TabPane>
                        ))}
                    </Tabs>
                </div>
            </div>
        </div>
    );
};

export default UsersTabDesktop;
