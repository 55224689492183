import axios from "axios";
import ApiUrls from "constants/api.constants";
import Methods from "constants/httpMethods.constants";

export const reprintVoucher = id => (
    axios({
        url: ApiUrls.REPRINT_VOUCHER,
        method: Methods.GET,
        params: {
            id
        },
    })
)
