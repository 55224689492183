import {
    SET_USER_INFO_ACTION_BEFORE,
    SET_USER_INFO_ACTION_FINISH,
    SET_USER_INFO,
    UPDATE_USER_INFO,
    SET_BALANCE,
    SET_LIMIT,
    SET_USER_AVAILABLE_CURRENCIES_ACTION_BEFORE,
    SET_USER_AVAILABLE_CURRENCIES_ACTION_FINISH,
    SET_USER_AVAILABLE_CURRENCIES,
    SET_CURRENT_PLAYER
} from "store/actionTypes";

export default (state = {}, { type, payload }) => {
    switch (type) {
        case SET_USER_INFO_ACTION_BEFORE:
            return {
                ...state,
                isUserInfoLoading: true,
            };
        case SET_USER_INFO_ACTION_FINISH:
            return {
                ...state,
                isUserInfoLoading: false,
            };
        case SET_USER_INFO:
            return {
                ...state,
                userInfo: payload
            };
        case UPDATE_USER_INFO:
            return {
                ...state,
                userInfo: {
                    ...state.userInfo,
                    ...payload
                }
            };
        case SET_BALANCE:
            return {
                ...state,
                userInfo: {
                    ...state.userInfo,
                    balance: payload
                }
            }
        case SET_LIMIT:
            return {
                ...state,
                userInfo: {
                    ...state.userInfo,
                    limit: payload
                }
            }
        case SET_USER_AVAILABLE_CURRENCIES_ACTION_BEFORE:
            return {
                ...state,
                isUserCurrenciesLoading: false,
            };
        case SET_USER_AVAILABLE_CURRENCIES_ACTION_FINISH:
            return {
                ...state,
                isUserCurrenciesLoading: false,
            };
        case SET_USER_AVAILABLE_CURRENCIES:
            return {
                ...state,
                currencies: payload.currencies,
            };
        case SET_CURRENT_PLAYER:
            return {
                ...state,
                playerInfo: payload,
            };
        default:
            return state;
    }
};
