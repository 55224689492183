import axios from "axios";

import Methods from "constants/httpMethods.constants";
import ApiUrls from "constants/api.constants";
import { RESPONSE_CODES } from "constants/errorCode.constants";

import { showError } from "utils/message";

import {
    SET_PROVIDER,
    LAUNCH_PROVIDER,
    LAUNCH_PROVIDER_FINISH,
    LAUNCH_PROVIDER_BEFORE,
    RESET_PROVIDERS, LAUNCH_ERROR
} from "store/actionTypes";

const launchProviderFinished = () => ({
    type: LAUNCH_PROVIDER_FINISH,
});

const launchProviderBefore = () => ({
    type: LAUNCH_PROVIDER_BEFORE,
});

const launchError = (hasError, id) => ({
    type: LAUNCH_ERROR,
    payload: { hasError, id }
})

const launchProvider = (data, id) => ({
    type: LAUNCH_PROVIDER,
    payload: {data, id}
});

export const resetProviders = () => ({
    type: RESET_PROVIDERS
});

export const setProvider = provider => ({
    type: SET_PROVIDER,
    payload: provider
})

export const launch = (providerId, languageCode) => {
    return dispatch => {
        dispatch(launchProviderBefore());
        dispatch(launchError(false, providerId));

        return axios({
            url: ApiUrls.LAUNCH_PROVIDER,
            method: Methods.POST,
            data: { type: providerId, languageCode: languageCode }
        })
            .then(({ data: { value: info } }) => {
                if (info.resultCode && info.resultCode !== RESPONSE_CODES.Success) {
                    showError(info.message);
                    dispatch(launchError(true, providerId));
                } else {
                    dispatch(launchProvider(info, providerId))
                }

                dispatch(launchProviderFinished());
            })
            .catch((ex) => {
                dispatch(launchProviderFinished());
                dispatch(launchError(true, providerId));
            })
    }
}
