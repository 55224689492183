import {DATA_TYPES, LEADERBOARD_TYPES} from "components/manager/dashboard/constants";


const getCorrespondingDataTypes = (type) => {
    const types = { ...DATA_TYPES };

    delete types.COMMISSIONS_LITE;

    if (type === LEADERBOARD_TYPES.PLAYER) {
        delete types.COMMISSIONS;
    }

    return types;
}

export default getCorrespondingDataTypes;
