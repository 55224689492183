import {
    getCheckPointsTooltipConfigs,
    getXAxisConfigs,
    getYAxisConfigs,
} from "./configCreators";

import { LINE_CHART_DEFAULT_CONFIGS } from "constants/lineChart.constants";


// chart: {
//     type: 'area'
// },
//
// title: {
//     text: 'Pattern fill plugin demo'
// },
//
// xAxis: {
//     categories: [
//         'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep',
//         'Oct', 'Nov', 'Dec'
//     ]
// },
//
// plotOptions: {
//     area: {
//         fillColor: {
//             pattern: {
//                 path: {
//                     d: 'M 0 0 L 10 10 M 9 -1 L 11 1 M -1 9 L 1 11',
//                         strokeWidth: 3
//                 },
//                 width: 10,
//                     height: 10,
//                     opacity: 0.4
//             }
//         }
//     }
// },
//
// series: [{
//     data: [29.9, 71.5, 106.4, 129.2, 144.0, 176.0, 135.6],
//     color: '#88e',
//     fillColor: {
//         pattern: {
//             color: '#11d'
//         }
//     }
// }, {
//     data: [
//         null, null, null, null, null, 43.1, 95.6, 148.5, 216.4, 194.1,
//         95.6, 54.4
//     ],
//     color: '#e88',
//     fillColor: {
//         pattern: {
//             color: '#d11'
//         }
//     }
// }]
// }


export const constructLineChartConfigs = ({
    xAxisConfigs = getXAxisConfigs(),
    yAxisConfigs = getYAxisConfigs(),
    checkPointsTooltipConfigs = getCheckPointsTooltipConfigs(),
    legend = {}, // For Constructing legend Configs Use <getLegendConfigs> Function
    lines = [], // For Constructing Each Line Configs Use <getMainLineConfigs> Function
} = {}) => {
    return {
        ...LINE_CHART_DEFAULT_CONFIGS,
        ...legend,
        xAxis: xAxisConfigs,
        yAxis: yAxisConfigs,
        tooltip: checkPointsTooltipConfigs,
        series: lines,
        plotOptions: {
            area: {
                fillColor: {
                    pattern: {
                        path: {
                            d: 'M 0 0 L 10 10 M 9 -1 L 11 1 M -1 9 L 1 11',
                            strokeWidth: 3
                        },
                        width: 10,
                        height: 10,
                        opacity: 0.4
                    }
                }
            }
        },
    }
}
