import React, {Fragment, useMemo} from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import Table from "components/common/table";
import Pagination from "components/common/pagination";
import Totals from "components/common/totals";

import {
    getBetHistory,
    getBetHistoryTotals,
    setBetHistoryFilters,
    setBetHistorySorting,
} from "store/actions/dashboard/betHistory.action";

import useFormat from "hooks/useFormat";

import { BET_TYPE } from "constants/bets.constants";

import { getBetStateText } from "utils/common";

import { getUser } from "utils/auth";
import { USER_ROLE } from "constants/user.constants";

import BetHistoryTotal from '../betHistoryTotal'

import noDataImg from "assets/images/noContent.png";

/** Bet history Table Component */
const BetHistory = ({
    getBetHistory,
    getBetHistoryTotals,
    setBetHistorySorting,
    setBetHistoryFilters,
    filters,
    isLoading,
    bets,
    sorting,
    total,
    totals,
    isTotalsLoading,
    columns,
    availableBetshops,
    type
}) => {
    const { t } = useTranslation();

    const { formatAmount } = useFormat();

    const mappedTotals = useMemo(() => {
        if (filters.betShopNameOrId) {
            const betShop = availableBetshops.find(availableBetshop => availableBetshop.id === filters.betShopNameOrId);

            if (!betShop) {
                return totals;
            }

            return {
                [betShop.currency]: totals[betShop.currency.toLowerCase()]
            }
        }

        return totals;
    }, [availableBetshops, filters.betShopNameOrId, totals]);


    const totalsColumns = [
        {
            key: "currencyCode",
            title: t("common.currency"),
        },
        {
            key: "betCount",
            title: t("common.betCount"),
        },
        {
            key: "betAmount",
            title: t("common.betAmount"),
        },
        {
            key: "winCount",
            title: t("common.winCount"),
        },
        {
            key: "winAmount",
            title: t("common.winAmount"),
        },
        {
            key: "paidCount",
            title: t("common.paidCount"),
        },
        {
            key: "paidAmount",
            title: t("common.paidAmount"),
        },
        {
            key: "pendingCount",
            title: t("common.pendingCount"),
        },

        {
            key: "pendingAmount",
            title: t("common.pendingAmount"),
        },
    ];

    /** Fields of row details table */
    const fields = [
        "id",
        "game",
        "status",
        "eventId",
        "factor",
        "amount",
        "possibleWin",
        "winning",
        "event",
        "market",
        "result",
    ];

    const renderField = (field, bet, betslip, isMultiBet) => {
        switch (field) {
        case "id":
            return {
            title: t("common.betId"),
            content: bet.id,
            };
        case "game":
            return {
            title: t("common.game"),
            content: bet.sportName,
            };
        case "status":
            return {
            title: t("common.status"),
            content: (
                <div className="rt--flex rt--align-center">
                <div
                    className="rt--table-bets-status-marker rt--mr-8"
                    data-state={bet.status}
                ></div>
                <span className="rt--title rt--font-small rt--font-regular">
                    {getBetStateText(bet.status)}
                </span>
                </div>
            ),
            };
        case "market":
            return {
                title: t("common.market"),
                content: bet.market || "-",
            };
        case "factor":
            return {
                title: t("common.odds"),
                content: bet.factor + "x",
            };
        case "amount":
            return {
                title: t("common.amount"),
                content:
                    !isMultiBet && bet.amount
                    ? formatAmount(Number(bet.amount), betslip.currencyCode)
                    : "-",
            };
        case "possibleWin":
            return {
                title: t("common.possibleWin"),
                content:
                    !isMultiBet && bet.possibleWin
                    ? formatAmount(Number(bet.possibleWin), betslip.currencyCode)
                    : "-",
                };
        case "winning":
            return {
                title: t("common.win"),
                content:
                    !isMultiBet && bet.winning
                    ? formatAmount(Number(bet.winning), betslip.currencyCode)
                    : "-",
                };
        case "eventId":
            return {
                title: t("common.eventId"),
                content: bet.eventId || "-",
            };
        case "event":
            return {
                title: t("common.event"),
                content: bet.event ? bet.event : "-",
            };
        case "result":
            return {
                title: t("common.result"),
                content: bet.result ? bet.result : "-",
            };
        default:
            return {
                title: "",
                content: "",
            };
        }
    };

    const renderRowContent = (record) => {
        return (
            <div className="rt--pt-12">
                {
                    record.bets?.length > 1 && (
                        <b className="rt--title rt--font-bold rt--font-normal rt--table-bets-title">
                            {t("common.bets")}
                        </b>
                    )
                }
                <div className="rt--table-bets-list">
                    {
                        record.bets?.map(
                            bet => (
                                <div
                                    className="rt--table-bets-list-item rt--pt-12 rt--pb-12 rt--flex rt--align-center"
                                    key={bet.id}
                                >
                                    {
                                        Array.from(Array(Math.ceil(fields.length / 2)).keys()).map(
                                            (key) => (
                                                <div className="rt--table-bets-list-item-col" key={key}>
                                                    <div className="rt--flex rt--align-center rt--mb-8">
                                                        <b className="rt--title rt--font-small rt--font-regular rt--font-capitalize">
                                                            { renderField(fields[2 * key], bet, record).title }:
                                                        </b>
                                                        <span
                                                            className="rt--title rt--font-small rt--font-regular rt--pl-8 rt--pr-8"
                                                            title={
                                                                renderField(
                                                                    fields[2 * key],
                                                                    bet,
                                                                    record,
                                                                    record.betType === BET_TYPE.MULTI
                                                                ).content
                                                            }
                                                        >
                                                            {
                                                                renderField(
                                                                    fields[2 * key],
                                                                    bet,
                                                                    record,
                                                                    record.betType === BET_TYPE.MULTI
                                                                ).content
                                                            }
                                                        </span>
                                                    </div>
                                                    {
                                                        fields[2 * key + 1] ? (
                                                            <div className="rt--flex rt--align-center">
                                                                <b className="rt--title rt--font-small rt--font-regular rt--font-capitalize">
                                                                    { renderField(fields[2 * key + 1], bet, record).title }:
                                                                </b>
                                                                <span
                                                                    className="rt--title rt--font-small rt--font-regular rt--pl-8 rt--pr-8"
                                                                    title={
                                                                        renderField(
                                                                            fields[2 * key + 1],
                                                                            bet,
                                                                            record,
                                                                            record.betType === BET_TYPE.MULTI
                                                                        ).content
                                                                    }
                                                                >
                                                                    {
                                                                        renderField(
                                                                            fields[2 * key + 1],
                                                                            bet,
                                                                            record,
                                                                            record.betType === BET_TYPE.MULTI
                                                                        ).content
                                                                    }
                                                                </span>
                                                            </div>
                                                        ) : (
                                                            <div className="rt--flex rt--align-center" />
                                                        )
                                                    }
                                                </div>
                                            )
                                        )
                                    }
                                </div>
                            )
                        )
                    }
                </div>
            </div>
        );
    };

    return (
        <Fragment>
            {
                getUser()?.role === USER_ROLE.CASHIER ? (
                    <BetHistoryTotal type={type} />
                ) : (
                    <Totals
                        loadFn={() => getBetHistoryTotals(type)}
                        totals={mappedTotals}
                        columns={totalsColumns}
                        isLoading={isTotalsLoading}
                    />
                )
            }
            <Table
                name="betHistory"
                loadFn={() => getBetHistory(type)}
                setSortingFn={sorting => setBetHistorySorting(sorting, type)}
                columns={columns}
                data={bets}
                isLoading={isLoading}
                sorting={sorting}
                emptyImg={noDataImg}
                emptyText={t("common.noBetFound")}
                actions={[]}
                setFiltersFn={filters => setBetHistoryFilters(filters, type)}
                filters={filters}
                uniqueKey="id"
                expandable={true}
                renderExpandContent={renderRowContent}
            />
            <Pagination
                total={total}
                onChange={(value) =>
                    setBetHistorySorting({
                        ...sorting,
                        ...value,
                    }, type)
                }
                page={sorting.page}
                limit={sorting.limit}
                loadFn={() => getBetHistory(type)}
            />
        </Fragment>
    );
};

const mapDispatchToProps = (dispatch) => ({
    getBetHistory: type => {
        dispatch(getBetHistory(type));
    },

    getBetHistoryTotals: type => {
        dispatch(getBetHistoryTotals(type));
    },

    setBetHistorySorting: (sorting, type) => {
        dispatch(setBetHistorySorting(sorting, type));
    },

    setBetHistoryFilters: (filters, type) => {
        dispatch(setBetHistoryFilters(filters, type));
    },
});

const mapStateToProps = ( state, props ) => {
    const type = props.type;

    return {
        isLoading: state.betHistory.isLoading,
        sorting: state.betHistory[type].sorting,
        bets: state.betHistory[type].bets,
        totals: state.betHistory[type].totals.totals,
        isTotalsLoading: state.betHistory[type].totals.isLoading,
        filters: state.betHistory[type].filters,
        total: state.betHistory[type].total,
        availableBetshops: state.managerBetshops.availableBetshops,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(BetHistory);
