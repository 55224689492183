import React, {useEffect, useMemo} from 'react';

import { useTranslation } from 'react-i18next';
import {Row, Tabs} from "antd";
import useDashboardState from "components/manager/dashboard/hooks/useDashboardState";
import StatisticsTab from "components/manager/dashboard/tabs/statisticsTab";
import {DASHBOARD_FILTERS, DASHBOARD_TAB_NAMES} from "components/manager/dashboard/tabs/constants";
import LastUpdated from "components/manager/dashboard/components/lastUpdated";
import {connect, useSelector} from "react-redux";
import {getManagerAvailableBetshops} from "store/actions/dashboard/managerBetshops.action";
import {getUserAvailableCurrencies} from "store/actions/dashboard/userInfo.action";
import {FINANCIAL_DASHBOARD_FILTER_NAMES} from "components/manager/dashboard/constants";
import UsersTab from "components/manager/dashboard/tabs/usersTab";
import {withDashboardContextProvider} from "components/manager/dashboard/contexts/dashboardContext";

const FiltersComponent = ({ activeTab, currentFilters, handleFiltersChange, currencyOptions }) => {
    const Filters = useMemo(() => DASHBOARD_FILTERS[activeTab], [activeTab]);

    if (!Filters) {
        return null
    }

    return (
        <Filters
            filters={currentFilters}
            onChange={handleFiltersChange(activeTab)}
            currencyOptions={currencyOptions}
        />
    );
};

/** Betshop Manager Dashboard Page */
const BetshopManagerDashboard = ({ getUserAvailableCurrencies }) => {
    const currencies = useSelector(state => state.profile.currencies);

    const { t } = useTranslation();
    const {
        lastUpdatedDate,
        setLastUpdatedDate,
        activeTab,
        setActiveTab,
        filters,
        setFilters
    } = useDashboardState(true, []);

    useEffect(() => {
        getUserAvailableCurrencies();
    }, []);

    useEffect(() => {
        if (currencies.length) {
            handleFiltersChange(DASHBOARD_TAB_NAMES.USERS)("leaderboard", { [FINANCIAL_DASHBOARD_FILTER_NAMES.CURRENCY]: currencies[0] });
            handleFiltersChange(DASHBOARD_TAB_NAMES.STATISTICS)("global", { [FINANCIAL_DASHBOARD_FILTER_NAMES.CURRENCY]: currencies[0] });
        }
    }, [currencies])

    const currencyOptions = useMemo(() => currencies.map(currency => ({
        text: currency,
        value: currency
    })), [currencies]);

    const currentFilters = useMemo(() => filters[activeTab], [filters, activeTab]);
    const statisticsFilters = useMemo(() => filters[DASHBOARD_TAB_NAMES.STATISTICS].global, [filters]);
    const usersFilters = useMemo(() => filters[DASHBOARD_TAB_NAMES.USERS], [filters]);

    const handleFiltersChange = (source) => (scope, values) => {
        setFilters(state => {
            if (scope) {
                return {
                    ...state,
                    [source]: {
                        ...state[source],
                        [scope]: {
                            ...state[source][scope],
                            ...values
                        }
                    }
                }
            }

            return {
                ...state,
                [source]: {
                    ...(Object.keys(values).reduce((acc, scope) => ({
                        ...acc,
                        [scope]: Object.keys(values[scope]).reduce((acc, key) => ({
                            ...acc,
                            [key]: values[scope][key]
                        }), { ...(state[source][scope] ?? {}) })
                    }), { ...state[source] }))
                }
            }
        });
    };

    return (
        <div className='rt--dashboard rt--mt-32'>
            <Tabs
                destroyInactiveTabPane
                className='rt--tabs'
                onChange={setActiveTab}
                activeKeys={[activeTab]}
                tabBarExtraContent={
                    <Row>
                        <LastUpdated lastUpdatedDate={lastUpdatedDate} />

                        <FiltersComponent
                            activeTab={activeTab}
                            currentFilters={currentFilters}
                            handleFiltersChange={handleFiltersChange}
                            currencyOptions={currencyOptions}
                        />
                    </Row>
                }
            >
                <Tabs.TabPane tab={t("common.statistics")} key={DASHBOARD_TAB_NAMES.STATISTICS}>
                    <StatisticsTab filters={statisticsFilters} updateLastUpdatedDate={setLastUpdatedDate} />
                </Tabs.TabPane>

                <Tabs.TabPane tab={t("common.users")} key={DASHBOARD_TAB_NAMES.USERS} className="rt--dashboard-users">
                    <UsersTab
                        filters={usersFilters}
                        currencyOptions={currencyOptions}
                        updateLastUpdatedDate={setLastUpdatedDate}
                        onFiltersChange={handleFiltersChange(DASHBOARD_TAB_NAMES.USERS)}
                    />
                </Tabs.TabPane>
            </Tabs>
        </div>
    )
}

const mapDispatchToProps = (dispatch) => ({
    getUserAvailableCurrencies: () => {
        dispatch(getUserAvailableCurrencies())
    },
});

export default connect(null, mapDispatchToProps)(withDashboardContextProvider(BetshopManagerDashboard));
