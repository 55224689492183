import React from 'react';

import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import HandoverComponent from 'components/auth/handover';

import withAnanymous from 'hocs/withAnanymous';


const HandoverRoute = () => {
    return (
        <GoogleReCaptchaProvider reCaptchaKey={import.meta.env.SYSTEM_CAPTCHA_KEY}>
            <HandoverComponent /> 
        </GoogleReCaptchaProvider>
    )
}

export default withAnanymous(HandoverRoute);