import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Modal, Input } from 'antd';

import Loader from "components/common/loader";
import PrintTicket from 'components/common/tickets/printTicket';
import Ticket from './ticket';

import { getTicketInfo } from 'store/actions/dashboard/ticket.action';

import { POPUP_SIZE } from "constants/popup.constants";

import payoutTicketType from 'types/payoutTicket.type';
import ticketInfoType from 'types/ticketInfo.type';

import NoTicketImg from "assets/images/noTicket.png";
import SearchImg from "assets/images/search.svg";

const STEPS = {
    SEARCH: 1,
    TICKET: 2,
    NORESULT: 3
}

/** Check Ticket  Component */

const CheckTicketComponent = ({
    handleCloseModal,
    getTicketInfo,
    ticketId,
    ticketInfo,
    isTicketInfoLoading,
    payoutTicket
}) => {

    const { t } = useTranslation();

    /** Current Step */
    const [currentStep, setStep] = useState(STEPS.SEARCH);

    const [ticketIdValue, setTicketIdValue] = useState(ticketId || "")

    /** Load ticket if data is provided(scan case) */
    useEffect(() => {
        if(ticketId){
            setStep(STEPS.SEARCH);
            setTicketIdValue(ticketId);
            loadTicket(ticketId);
        }
    }, [ticketId])

    const isSearchButtonDisabled = ticketIdValue.trim().length < 5


    /** Function, to call on search button click
		 * @function
		 * @memberOf CheckTicketComponent
    */
    const handleSearch = () => {
        if (!isSearchButtonDisabled) {
            loadTicket(ticketIdValue)
        }
    }

    /** Function, to load ticket
		 * @function
         * @param {string} id - ticket ID
		 * @memberOf CheckTicketComponent
    */
    const loadTicket = id => {
        getTicketInfo(id, data => {
            if(data.length > 0){
                setStep(STEPS.TICKET)
            } else {
                setStep(STEPS.NORESULT)
            }
        })
    }


    /** Function, to render popup content
		 * @function
         * @param {number} step - step
         * @returns {JSX}
		 * @memberOf CheckTicketComponent
    */
    const renderContent = step => {
        switch (step) {
            case STEPS.SEARCH:
            case STEPS.NORESULT:
                return !isTicketInfoLoading ? (
                    <div>
                        <div className='rt--modal-form-item rt--flex rt--flex-col'>
                            <span className='rt--title rt--font-medium rt--font-normal rt--mb-8'>{t("common.ticketId")}</span>
                            <div className='rt--flex rt--align-center'>
                                <Input
                                    className='rt--input'
                                    placeholder={`${t("common.enter")} ${t("common.ticketId")}`}
                                    onChange={e => setTicketIdValue(e.target.value)}
                                    value={ticketIdValue}
                                    maxLength={30}
                                />
                                <button
                                    className={"rt--button-complimentary rt--button rt--pl-16 rt--pr-16 rt--ml-8" + (isSearchButtonDisabled ? " rt--button-disabled" : "")}
                                    onClick={handleSearch}
                                    disabled={isSearchButtonDisabled}
                                >
                                    <span className='rt--title rt--font-normal rt--font-regular'>{t('common.search')}</span>
                                </button>
                            </div>
                        </div>
                        <div className='rt--flex rt--flex-col rt--align-center rt--justify-center rt--pt-54 rt--pb-54'>
                            <img alt="icon" src={step === STEPS.NORESULT ? NoTicketImg : SearchImg} />
                            <span className='rt--title rt--font-normal rt--font-medium rt--pt-8 rt--modal-check-ticket-search-info rt--text-center'>{step === STEPS.NORESULT ? t("common.noTicketFound") : t("common.searchToSeeTicketInfo")}</span>
                        </div>
                    </div>
                ) : <Loader />;
            case STEPS.TICKET:
                return (
                    <div>
                        <Ticket
                            ticketInfo={ticketInfo}
                            onCancel={() => {
                                setStep(STEPS.SEARCH);
                                setTicketIdValue("");
                            }}
                        />
                    </div>
                )
            default:
                return <Fragment />
        }
    }

    return (
        <Modal
            className={'rt--modal' + (currentStep === STEPS.TICKET ? " rt--modal-full-width" : "")}
            title={(
                <div className='rt--flex rt--justify-between rt--align-center'>
                    <span className='rt--title rt--font-normal rt--font-regular'>{t("common.checkTicket")}</span>
                    <i className='icon-close rt--font-bigest rt--cursor-pointer' onClick={() => handleCloseModal()} />
                </div>
            )}
            visible={true}
            closable={false}
            maskClosable={false}
            cancelText={t('common.cancel')}
            okText={t('common.done')}
            getContainer={() => document.getElementById('rt--modal-root')}
            width={POPUP_SIZE.SMALL}
            centered
            footer={null}
            {...(currentStep === STEPS.TICKET ? {} : { footer: null })} 
        >

            <div className='rt--modal-check-ticket'>
                {isTicketInfoLoading ? <Loader /> : renderContent(currentStep)}
            </div>
            <div className='rt--display-none'>
                {payoutTicket.length && <PrintTicket />}
            </div>
        </Modal>
    )

}

/** CheckTicketComponent propTypes
    * PropTypes
*/
CheckTicketComponent.propTypes = {
    /** Function to close popup */
    handleCloseModal: PropTypes.func,
    /** Redux state property, ticket info */
    ticketInfo: PropTypes.arrayOf(ticketInfoType),
    /** Redux state property, is true when loading ticket  */
    isTicketInfoLoading: PropTypes.bool,
    /** Redux state property, array of paidout tickets */
    payoutTicket: PropTypes.arrayOf(payoutTicketType),
    /** Redux action to get ticket info */
    getTicketInfo: PropTypes.func,
    /** Passed ticket ID */
    ticketId: PropTypes.string
}

const mapDispatchToProps = dispatch => (
    {
        getTicketInfo: (ticketId, onSuccess) => {
            dispatch(getTicketInfo(ticketId, onSuccess))
        }
    }
)

const mapStateToProps = state => {
    return {
        ticketInfo: state.ticket.ticketInfo,
        isTicketInfoLoading: state.ticket.isTicketInfoLoading,
        payoutTicket: state.ticket.payoutTicket
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    CheckTicketComponent
);