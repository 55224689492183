import React, {useState} from 'react';
import {Button, Modal, Tooltip} from "antd";
import {useTranslation} from "react-i18next";
import VoucherTicket from "components/common/tickets/voucher";
import {printElement} from "utils/common";
import {VOUCHER_STATE} from "constants/voucher.constants";
import {getUser} from "utils/auth";
import {USER_ROLE} from "constants/user.constants";
import {reprintVoucher} from "components/common/voucherHistory/reprintModal/api";

const ReprintModal = ({ voucher: { id: voucherId } }) => {
    const [voucher, setVoucher] = useState(null);
    const [loading, setLoading] = useState(false);
    const [opened, setOpened] = useState(false);

    const { t } = useTranslation();

    const handleOpenModal = () => {
        setOpened(true);
    }

    const handleCloseModal = () => {
        setOpened(false);
    }

    const handleReprint = () => {
        setLoading(true);

        reprintVoucher(voucherId)
            .then(({ data: { value } }) => {
                if (value) {
                    setVoucher(value);

                    setTimeout(() => {
                        printElement(value.id, () => {
                            handleCloseModal();
                        });
                    }, 100);
                } else {
                    handleCloseModal();
                }
            })
            .finally(() => {
                setLoading(false);
            })
    }

    return (
        <>
            <Tooltip title={t("common.reprint")} placement="left" style={{width: "100px"}}>
                <Button type="ghost" onClick={handleOpenModal}>
                    <i className="icon-printer rt--font-bigest"/>
                </Button>
            </Tooltip>

            <Modal
                className='rt--modal rt--modal-voucher-reprint'
                title={(
                    <div className='rt--flex rt--justify-between rt--align-center'>
                        <span className='rt--title rt--font-normal rt--font-regular'>{t("common.reprintVoucher")}</span>
                        <i className='icon-close rt--font-bigest rt--cursor-pointer' onClick={handleCloseModal} />
                    </div>
                )}
                visible={opened}
                onCancel={handleCloseModal}
                closable={false}
                maskClosable={false}
                getContainer={() => document.getElementById('rt--modal-root')}
                cancelButtonProps={{ className: 'rt--button-secondary' }}
                cancelText={t('common.cancel')}
                okText={t('common.reprint')}
                onOk={handleReprint}
                confirmLoading={loading}
                centered
                width={"fit-content"}
            >
                <span>
                    {t("common.reprintVoucherMessage")}
                </span>
            </Modal>

            {(
                voucher &&
                voucher.status === VOUCHER_STATE.ACTIVE &&
                getUser()?.role === USER_ROLE.CASHIER &&
                <div style={{ display: 'none' }}><VoucherTicket voucher={voucher} reprint /></div>
            )}
        </>
    );
};

export default ReprintModal;
