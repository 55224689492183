import React, { useMemo } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import useFormat from "hooks/useFormat";

import getColumns from "../helpers/getColumns";

import Table from "components/common/table/index";
import Pagination from "components/common/pagination/index";

import noDataImg from "assets/images/noContent.png";

import { getTerminals, setTerminalsFilters, setTerminalsSorting} from "store/actions/dashboard/terminals.action";

const TerminalsTable = ({ 
    terminals, 
    isLoading, 
    total, 
    sorting, 
    filters, 
    getTerminals, 
    setTerminalsFilters, 
    setTerminalsSorting ,
    betShopId,
    userInfo
}) => {
    const { t } = useTranslation();
    const { formatAmount } = useFormat();

    const allowBlockTerminal = userInfo.allowBlockTerminal;

    const columns = useMemo(() => getColumns({ t, formatAmount, betShopId, allowBlockTerminal }), [t, formatAmount, betShopId, allowBlockTerminal]);

    return (
        <>
            <div className="rt--flex-1 rt--overflow-hidden">
                <Table
                    name={"terminals"}
                    loadFn={() => getTerminals(betShopId)}
                    columns={columns}
                    data={terminals}
                    isLoading={isLoading}
                    sorting={sorting}
                    emptyImg={noDataImg}
                    emptyText={t("common.noTerminalFound")}
                    setFiltersFn={f => setTerminalsFilters(f)}
                    filters={filters}
                    uniqueKey="id"
                />
            </div>
            <Pagination
                total={total}
                onChange={value => setTerminalsSorting({
                    ...value
                })}
                page={sorting.page}
                limit={sorting.limit}
                loadFn={() => getTerminals(betShopId)}
            />
        </>
    );
};

const mapStateToProps = state => ({
    isLoading: state.terminals.isLoading,
    sorting: state.terminals.sorting,
    terminals: state.terminals.terminals,
    total: state.terminals.total,
    filters: state.terminals.filters,
    userInfo: state.profile.userInfo
})

const mapDispatchToProps = dispatch => ({
    getTerminals: betShopId => dispatch(getTerminals(betShopId)),
    setTerminalsFilters: (filters) => dispatch(setTerminalsFilters(filters)),
    setTerminalsSorting: (filters) => dispatch(setTerminalsSorting(filters)),
})

export default connect(mapStateToProps, mapDispatchToProps)(TerminalsTable);
