const PATHS = {
    AUTHENTICATE: "/Account/Login",
    REFRESH_TOKEN: "/Account/RefreshToken",
    RESET_PASSWORD: "/Account/ResetPassword",
    SET_PASSWORD: "/Account/SetPassword",
    GET_USER_INFO: "/Account/Info",
    GET_PASSWORD_SETTINGS: "/Account/PasswordSettings",
    GET_AVAILABLE_CURRENCIES: "/Account/AvailableCurrencies",

    SET_PLAYER: "/Cashier/SetPlayer",
    RESET_PLAYER: "/Cashier/ResetPlayer",

    GET_SYSTEM_LANGUAGES: "/Language/SystemAvailable",

    GET_BETSHOPS: "/Account/BetShops",
    CHANGE_BETSHOP: "/Account/ChangeBetShop",

    LAUNCH_PROVIDER: "/Retail/Launch",

    GET_BARCODE_INFO: "/Bet/Barcode",
    PAYOUT: "/Bet/Payout",

    GET_CASHIER_BET_HISTORY: "/Bet/History",
    GET_TERMINAL_BET_HISTORY: "/Bet/TerminalHistory",

    GET_CASHIER_BETS_TOTALS: "/Bet/RetailBetsTotals",
    GET_TERMINAL_BETS_TOTALS: "/Bet/TerminalBetsTotals",

    EXPORT_CASHIER_BET_HISTORY: "/Bet/ExportHistory",
    EXPORT_TERMINAL_BET_HISTORY: "/Bet/ExportTerminalHistory",

    GET_PENDING_BETS: "/Bet/PendingBets",

    CREATE_PLAYER: "/Player/Create",
    GET_PLAYER_REGISTRATION_FORM: "/Player/RegistrationForm",
    GET_PLAYER_AVAILABLE_CURRENCIES: "/Player/AvailableCurrencies",
    GET_PLAYER_INFO: "/Player/GeneralInfo",
    GET_PLAYER_WITHDRAWALS: "/Player/Withdrawals",

    GET_MANAGER_BETSHOPS: "/BetShop/All",
    EXPORT_MANAGER_BETSHOPS: "/BetShop/Export",
    GET_MANAGER_BETSHOP_AVAILABLE_CASHIERS_FOR_ADD: "/BetShop/AvailableCashiersForAdd",
    GET_MANAGER_BETSHOP_AVAILABLE_CASHIERS_FOR_TRANSFER: "/BetShop/AvailableCashiersForTransfer",
    GET_MANAGER_BETSHOP_AVAILABLE_BETSHOPS: "/BetShop/Available",

    GET_CASHIERS: "/Cashier/All",
    EXPORT_CASHIERS: "/Cashier/Export",
    CASHIER_ADD_BETSHOP_ACCESS: "/Cashier/AddBetShopAccess",
    CASHIER_REMOVE_BETSHOP_ACCESS: "/Cashier/RemoveBetShopAccess",
    CASHIER_CHANGE_STATE: "/Cashier/ChangeState",

    GET_AVAILABLE_CASHIERS_FOR_HANDOVER: "/Cashier/AvailableCashiersForHandover",
    CASHIER_HANDOVER: "/Cashier/Handover",
    CASHIER_REJECT_HANDOVER: "/Cashier/RejectHandover",

    GET_MANAGER_AVAILABLE_CASHIERS: "/Cashier/AvailableCashiers",

    GET_CASHIER_SESSIONS: "/Session/CashiersSessions",
    EXPORT_CASHIER_SESSIONS: "/Session/Export",

    WALLET_CASHIER_TRANSACTION: "/Wallet/CashierTransaction",
    WALLET_RESET_BETSHOP_LIMIT: "/Wallet/ResetBetShopLimit",
    PLAYER_DEPOSIT: "/Wallet/PlayerDeposit",
    PLAYER_WITHDRAW: "/Wallet/PlayerWithdraw",

    GET_CASHIER_TRANSACTIONS: "/Report/CashierTransactions",
    GET_BETSHOP_TRANSACTIONS: "/Report/BetShopTransactions",
    EXPORT_BETSHOP_TRANSACTIONS: "/Report/ExportBetShopTransactions",
    GET_BETSHOP_PERFORMANCE_REPORT: "/Report/BetShopPerformance",
    GET_BETSHOP_PERFORMANCE_TOTALS: "/Report/BetShopPerformanceTotals",
    EXPORT_BETSHOP_PERFORMANCE_REPORT: "/Report/ExportBetshopPerformance",
    GET_CASHIER_PERFORMANCE_REPORT: "/Report/CashierPerformance",
    EXPORT_CASHIER_PERFORMANCE_REPORT: "/Report/ExportCashierPerformance",

    GET_TERMINALS: "/Terminal/All",
    EXPORT_TERMINALS: "/Terminal/Export",
    CHANGE_TERMINAL_STATE: "/Terminal/ChangeState",

    GET_VOUCHERS: "/Voucher/All",
    EXPORT_VOUCHERS: "/Voucher/ExportVoucherHistory",
    GET_VOUCHERS_TOTALS: "/Voucher/VouchersHistoryTotals",
    CREATE_VOUCHER: "/Voucher/Create",
    REPRINT_VOUCHER: "/Voucher/Reprint",

    GET_TERMINAL_AUTOSUGGESTION: "/Autosuggestion/Terminals",
    GET_CASHIER_AUTOSUGGESTION: "/Autosuggestion/Cashiers",
    AUTOSUGGESTION_GET_BETSHOPS: "/Autosuggestion/BetShops",
    AUTOSUGGESTION_GET_CASHIERS: "/Autosuggestion/Cashiers",
    AUTOSUGGESTION_GET_BETSHOP_MANAGERS: "/Autosuggestion/BetShopManagers",
    AUTOSUGGESTION_GET_TERMINALS: "/Autosuggestion/Terminals",
    AUTOSUGGESTION_GET_PROJECTS: "/Autosuggestion/Projects",
    AUTOSUGGESTION_GET_COMPANIES: "/Autosuggestion/Companies",
    AUTOSUGGESTION_GET_USERS: "/Autosuggestion/Users",
    AUTOSUGGESTION_GET_ACCESS_MANAGERS: "/Autosuggestion/AccessManagers",
    AUTOSUGGESTION_GET_AGENTS: "/Autosuggestion/SubAgents",
    AUTOSUGGESTION_GET_PLAYERS: "/Autosuggestion/Players",
    AUTOSUGGESTION_GET_PERMISSION_GROUPS: "/Autosuggestion/PermissionGroups",
    AUTOSUGGESTION_GET_CONTROLLERS: "/Autosuggestion/Controllers",
    AUTOSUGGESTION_GET_BANKS: "/Autosuggestion/Banks",
    AUTOSUGGESTION_GET_COMMISSION_PLANS: "/Autosuggestion/Commissions",
    AUTOSUGGESTION_GET_PERMISSION_GROUP_AVAILABLE_USERS: "/Autosuggestion/PermissionGroupAvailableUsers",
    AUTOSUGGESTION_GET_PLAYERS_TOP: "/Autosuggestion/PlayersTop",

    GET_DASHBOARD_OPERATIONS_PIE_CHART: "/Dashboard/PieChart",
    GET_DASHBOARD_OPERATIONS_LINE_CHART: "/Dashboard/OperationsLineChart",
    GET_DASHBOARD_OPERATIONS_TOP_AGENTS: "/Dashboard/OperationsTopAgents",

    GET_DASHBOARD_FINANCES_TOP_AGENTS: "/Dashboard/FinancesTopAgents",
    GET_DASHBOARD_FINANCES_TOP_PLAYERS: "/Dashboard/FinancesTopPlayers",
    GET_DASHBOARD_FINANCES_LINE_CHART: "/Dashboard/FinancesLineChart",
    GET_DASHBOARD_RETAIL_LINE_CHART: "/Dashboard/RetailLineChart",
    GET_DASHBOARD_LINE_CHART: "/Dashboard/LineChart",

    GET_DASHBOARD_TOP_CASHIERS: "/Dashboard/TopCashiers",
    GET_DASHBOARD_TOP_BETSHOPS: "/Dashboard/TopBetshops",
    GET_DASHBOARD_TOP_PLAYERS: "/Dashboard/TopPlayers",
    GET_DASHBOARD_TOP_BETSHOP_OWNERS: "/Dashboard/TopBetshopOwners",

    GET_DASHBOARD_CARDS: "/Dashboard/Cards",
}

export default PATHS;
