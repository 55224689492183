import {createContext, useCallback, useContext, useState} from "react";
import {LEADERBOARD_TYPES} from "components/manager/dashboard/constants";

const initialState = {
    leaderboardTab: LEADERBOARD_TYPES.CASHIER
}

const DashboardContext = createContext({
    state: initialState,
    setLeaderboardTab: () => {}
});

export const useDashboardContext = () => useContext(DashboardContext);

export const DashboardContextProvider = ({ children }) => {
    const [state, setState] = useState(initialState);

    const setLeaderboardTab = useCallback((tab) => {
        setState(state => ({ ...state, leaderboardTab: tab }))
    }, []);

    return (
        <DashboardContext.Provider value={{state, setLeaderboardTab}}>
            {children}
        </DashboardContext.Provider>
    )
}

export const withDashboardContextProvider = (Component) => (props) => (
    <DashboardContextProvider>
        <Component {...props} />
    </DashboardContextProvider>
)
