import React from "react";
import moment from "moment";
import {DATE_TIME_FORMAT} from "constants/common.constants";
import {getVoucherStatusText} from "utils/common";
import {VOUCHER_STATE} from "constants/voucher.constants";
import ReprintModal from "components/common/voucherHistory/reprintModal";
import {getUser} from "utils/auth";
import {USER_ROLE} from "constants/user.constants";
import {Tooltip} from "antd";

const getColumns = ({ t, formatAmount, isCashier, reprintVoucher }) => [
    {
        key: "id",
        title: t("common.id"),
        copy: true,
        totalsRender: () => <span className='rt--title rt--font-normal rt--font-regular'>{t("common.totalAmount")}</span>
    },
    {
        key: "createdByName",
        title: t("common.createdBy"),
    },
    {
        key: "createdAt",
        title: t("common.createdAt"),
        render: value => (
            <Tooltip
                visible={!value ? false : undefined}
                placement="bottomLeft"
                title={(
                    <div className="rt--table-tooltip">
                        <span
                            className='rt--title rt--font-normal rt--font-regular'
                        >
                            {value ? moment.utc(value).local().format(DATE_TIME_FORMAT) : "-"}
                        </span>
                    </div>
                )}
            >
                <span className='rt--title rt--font-normal rt--font-regular'>{value ? moment.utc(value).local().format(DATE_TIME_FORMAT) : "-"}</span>
            </Tooltip>
        ),
        sorting: true
    },
    {
        key: "amount",
        title: t("common.amount"),
        render: (value, record) => <span className='rt--title rt--font-normal rt--font-regular'>{formatAmount(value, record.currencyCode)}</span>,
        totalsRender: (totals) => <span className='rt--title rt--font-normal rt--font-regular'>{formatAmount(totals.amount, totals.currencyCode)}</span>,
    },
    {
        key: "currencyCode",
        title: t("common.currency"),
    },
    {
        key: "usedByName",
        title: t("common.usedBy"),
        render: (value) => <span className='rt--title rt--font-normal rt--font-regular'>{value || "-"}</span>
    },
    !isCashier && {
        key: "betShopName",
        title: t("common.betshop"),
        render: value => (
            <Tooltip
                visible={!value ? false : undefined}
                placement="bottomLeft"
                title={(
                    <div className="rt--table-tooltip">
                        <span
                            className='rt--title rt--font-normal rt--font-regular'
                        >
                            {value}
                        </span>
                    </div>
                )}
            >
                <span className='rt--title rt--font-normal rt--font-regular'>{value || "-"}</span>
            </Tooltip>
        ),
    },
    {
        key: "status",
        title: t("common.status"),
        render: value => (
            <div
                className='rt--flex rt--align-center rt--status'
                data-type={value === VOUCHER_STATE.ACTIVE ? "success" : [VOUCHER_STATE.PAID_OUT, VOUCHER_STATE.USED].includes(value) ? "pending" : "error"}
            >
                <span className='rt--title rt--font-normal rt--font-regular rt--font-capitalize'>{getVoucherStatusText(value)}</span>
            </div>
        ),
    },
    {
        key: "paidOutByName",
        title: t("common.paidOutBy"),
        render: (value) => <span className='rt--title rt--font-normal rt--font-regular'>{value || "-"}</span>
    },
    {
        key: "paidOutDate",
        title: t("common.payoutDate"),
        render: value => (
            <Tooltip
                visible={!value ? false : undefined}
                placement="bottomLeft"
                title={(
                    <div className="rt--table-tooltip">
                        <span
                            className='rt--title rt--font-normal rt--font-regular'
                        >
                            {value ? moment.utc(value).local().format(DATE_TIME_FORMAT) : "-"}
                        </span>
                    </div>
                )}
            >
                <span className='rt--title rt--font-normal rt--font-regular'>{value ? moment.utc(value).local().format(DATE_TIME_FORMAT) : "-"}</span>
            </Tooltip>
        ),
    },
    {
        key: "expirationDate",
        title: t("common.expirationDate"),
        render: value => (
            <Tooltip
                visible={!value ? false : undefined}
                placement="bottomLeft"
                title={(
                    <div className="rt--table-tooltip">
                        <span
                            className='rt--title rt--font-normal rt--font-regular'
                        >
                            {value ? moment.utc(value).local().format(DATE_TIME_FORMAT) : "-"}
                        </span>
                    </div>
                )}
            >
                <span className='rt--title rt--font-normal rt--font-regular'>{value ? moment.utc(value).local().format(DATE_TIME_FORMAT) : "-"}</span>
            </Tooltip>
        ),
    },
    isCashier && reprintVoucher && {
        key: 'actions',
        disabled: true,
        render: (_, record) => record.status === VOUCHER_STATE.ACTIVE && getUser()?.role === USER_ROLE.CASHIER && <ReprintModal voucher={record} />
    }
]

export default getColumns;
