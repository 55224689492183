import {getSelectFieldOptions} from "utils/dashboard";
import getCorrespondingDataTypes from "components/manager/dashboard/helpers/getCorrespondingDataTypes";
import {
    DATA_TYPE_TRANSLATION_SOURCE,
    LEADERBOARD_TYPES,
    OPERATIONAL_DATA_TYPE_TRANSLATION_SOURCE
} from "components/manager/dashboard/constants";
import { mapOperationalDataTypesToFakeValues } from "components/manager/dashboard/helpers/mapOperationalDataTypesToFakeValues";
import getCorrespondingOperationalDataTypes from "components/manager/dashboard/helpers/getCorrespondingOperationalDataTypes";

const getDataTypeOptions = (type, hasLiteMode, hasRetail) => {
    let options = getSelectFieldOptions({
        dataSource: getCorrespondingDataTypes(type, hasLiteMode),
        translationSource: DATA_TYPE_TRANSLATION_SOURCE
    });

    if (type === LEADERBOARD_TYPES.AGENT) {
        options = [
            ...options,
            ...getSelectFieldOptions({
                dataSource: mapOperationalDataTypesToFakeValues(
                    getCorrespondingOperationalDataTypes(type, hasLiteMode, hasRetail)
                ),
                translationSource: OPERATIONAL_DATA_TYPE_TRANSLATION_SOURCE
            })
        ]
    }

    return options
}

export default getDataTypeOptions;
