import React, {Fragment, useMemo, useState} from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation, useParams } from 'react-router-dom';

import Paths from 'constants/path.constants';
import {isTerminalProject} from "utils/access";
import {connect} from "react-redux";

/** Leftbar Menu items */
const menuItems = [
    {
        key: "dashboard",
        path: Paths.MANAGER_DASHBOARD,
        icon: "icon-dashboard",
        title: "common.dashboard",
    },
    {
        key: "betshops",
        path: Paths.MANAGER,
        icon: "icon-betshops",
        title: "common.betshops",
        relativePaths: [Paths.MANAGER_BETSHOP_EDIT]
    },
    {
        key: "reports",
        icon: "icon-reports",
        title: "common.reports",
        sub: [
            {
                key: "cashierSessions",
                path: Paths.MANAGER_SESSIONS,
                title: "common.cashierSessions"
            },
            {
                key: "cashierTransactions",
                path: Paths.MANAGER_CASHIER_TRANSACTIONS,
                title: "common.cashierTransactions"
            },
            {
                key: "betshopTransactions",
                path: Paths.MANAGER_BETSHOP_TRANSACTIONS,
                title: "common.betshopTransactions"
            },
            {
                key: "betshopPerformanceReport",
                path: Paths.MANAGER_BETSHOP_PERFORMANCE_REPORT,
                title: "common.betshopPerformanceReport",
                relativePaths: [Paths.MANAGER_CASHIER_PERFORMANCE_REPORT]
            }
        ]
    },
    {
        key: "history",
        icon: "icon-betslips",
        title: "common.history",
        sub: [
            {
                key: "betHistory",
                path: Paths.MANAGER_BET_HISTORY,
                title: "common.betHistory"
            },
            {
                key: "voucherHistory",
                path: Paths.MANAGER_VOUCHER_HISTORY,
                title: "common.voucherHistory",
                hidden: ({ userInfo }) => !isTerminalProject(userInfo.projectType)
            }
        ]
    }
];

const filterSubItems = (items, params) => {
    return items.filter(item => {
        if (item.hidden === undefined) {
            return true;
        }

        if (typeof item.hidden === "function") {
            return !item.hidden(params);
        }

        return !item.hidden;
    });
}

const filterMenuItems = (items, params) => {
    return items.map(item => {
        item = {...item};

        if (item.sub) {
            item.sub = filterSubItems(item.sub, params);
        }

        return item;
    })
}

/** Betshop Manager Leftbar Component */
const LeftBar = ({ userInfo }) => {

    const { t } = useTranslation();

    const navigate = useNavigate();

    const { pathname, search } = useLocation();
    const params = useParams();

    /** Is menu expended */
    const [expended, setExpended] = useState(true);


    /** Function to check if menu item is active
       * @function
       * @param {object} item
       * @returns {boolean}
       * @memberOf LeftBar
    */
    const isMenuItemActive = item => {
        let p = pathname;
        if (params) {
            Object.keys(params).forEach(k => {
                p = p.replace(params[k], ":" + k)
            })
        }

        return p === item.path || (item.relativePaths && item.relativePaths.some(s => p.startsWith(s))) || (item.sub && item.sub.some(s => s.path === p));
    }

    const filteredMenuItems = filterMenuItems([...menuItems], { userInfo });

    /** Open Menu items keys */
    const [openedKeys, setOpenedKeys] = useState(filteredMenuItems.filter(item => item.sub && item.sub.some(sub => isMenuItemActive(sub))).map(item => item.key))


    /** Function to call on menu item click
       * @function
       * @param {object} item
       * @memberOf LeftBar
    */
    const handleClick = item => {
        if (!isMenuItemActive(item)) {
            navigate(item.path)
        }
    }

    /** Function to call on menu item click, if menu item has sub items
       * @function
       * @param {string} key
       * @memberOf LeftBar
    */
    const handleToggle = key => {
        setOpenedKeys(prev => prev.includes(key) ? prev.filter(k => k !== key) : prev.concat([key]))
    }

    return (
        <div
            className={'rt--betshop-manager-leftBar ' + (expended ? 'rt--betshop-manager-leftBar-opened' : 'rt--betshop-manager-leftBar-closed')}
        >
            <div className='rt--betshop-manager-leftBar-menu rt--pt-16 rt--pr-16 rt--pl-16 '>
                <button onClick={() => setExpended(!expended)}>
                    <i className='icon-menu rt--font-bigest'></i>
                </button>
                <ul className='rt--mt-40'>
                    {
                        filteredMenuItems.map(item => (
                            <Fragment key={item.key}>
                                <li className='rt--mb-24' >
                                    <div
                                        className={'rt--flex rt--justify-between rt--align-center rt--betshop-manager-leftBar-menu-item' + (isMenuItemActive(item) ? " rt--betshop-manager-leftBar-menu-item-active" : "")}
                                        data-opened={expended ? openedKeys.includes(item.key) : openedKeys.includes(null)}

                                        onClick={() => {
                                            if (item.sub) {
                                                handleToggle(item.key)
                                            } else {
                                                handleClick(item)
                                            }
                                        }}
                                    >
                                        <div className='rt--flex rt--justify-start rt--align-center' title={t(item.title)}>
                                            <i className={`${item.icon} rt--font-bigest`}></i>
                                            <span className='rt--title rt--font-normal rt--font-regular rt--ml-8'>{t(item.title)}</span>
                                        </div>
                                        {
                                            item.sub && <i className='icon-down rt--font-bigest' />
                                        }
                                    </div>
                                    {
                                        item.sub && (
                                            <ul
                                                className='rt--betshop-manager-leftBar-sub-menu rt--ml-30'
                                            >
                                                {
                                                    item.sub.map(subitem => (
                                                        <li key={subitem.key}>
                                                            <div
                                                                className={'rt--flex rt--justify-start rt--align-center rt--betshop-manager-leftBar-sub-menu-item' + (isMenuItemActive(subitem) ? " rt--betshop-manager-leftBar-sub-menu-item-active" : "")}
                                                                onClick={() => handleClick(subitem)}
                                                                title={t(subitem.title)}
                                                            >
                                                                <span className='rt--title rt--font-small rt--font-regular rt--pl-8'>{t(subitem.title)}</span>
                                                            </div>
                                                        </li>
                                                    ))
                                                }

                                            </ul>
                                        )
                                    }
                                </li>
                            </Fragment>
                        ))
                    }
                </ul>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        userInfo: state.profile.userInfo
    }
}

export default connect(mapStateToProps)(LeftBar);
