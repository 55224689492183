import axios from "axios";
import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { TIME_PERIOD } from "../constants";

import dateService from "utils/date"

export const getRetailLineChartData = ({ currencyCode, timePeriod, betShopNameOrId, dataType }) => {
    const abortController = new AbortController();

    const request = axios({
        url: ApiUrls.GET_DASHBOARD_LINE_CHART,
        method: Methods.GET,
        signal: abortController.signal,
        params: {
            betShopNameOrId,
            currencyCode,
            timePeriod,
            dataType,
            timeZone: dateService.getTimeZone()
        }
    })

    const cancelRequest = () => abortController.abort();

    return { request, cancelRequest };
}

export const getFinancialLineChartData = ({ currencyCode, timePeriod, dataType }) => {
    const abortController = new AbortController();

    const request = axios({
        url: ApiUrls.GET_DASHBOARD_FINANCES_LINE_CHART,
        method: Methods.GET,
        signal: abortController.signal,
        params: {
            currencyCode,
            timePeriod,
            dataType,
            timeZone: dateService.getTimeZone()
        }
    })

    const cancelRequest = () => abortController.abort();

    return { request, cancelRequest };
}

export const getFinancialLeaderBoardData = ({ currencyCode, timePeriod, dataType, endpoint, betShopNameOrId }) => {
    const abortController = new AbortController();

    const request = axios({
        url: endpoint,
        method: Methods.GET,
        signal: abortController.signal,
        params: {
            currencyCode,
            timePeriod,
            dataType,
            betShopNameOrId,
            timeZone: dateService.getTimeZone()
        }
    })

    const cancelRequest = () => abortController.abort();

    return { request, cancelRequest };
}

export const getOperationalPieChartData = ({ entityType }) => {
    const abortController = new AbortController();

    const request = axios({
        url: ApiUrls.GET_DASHBOARD_OPERATIONS_PIE_CHART,
        method: Methods.GET,
        signal: abortController.signal,
        params: {
            entityType,
            timePeriod: TIME_PERIOD.TODAY,
            timeZone: dateService.getTimeZone()
        }
    })

    const cancelRequest = () => abortController.abort();

    return { request, cancelRequest };
}

export const getOperationalLineChartData = ({ entityType, timePeriod }) => {
    const abortController = new AbortController();

    const request = axios({
        url: ApiUrls.GET_DASHBOARD_OPERATIONS_LINE_CHART,
        method: Methods.GET,
        signal: abortController.signal,
        params: {
            entityType,
            timePeriod,
            timeZone: dateService.getTimeZone()
        }
    })

    const cancelRequest = () => abortController.abort();

    return { request, cancelRequest };
}

export const getOperationalTopAgents = ({ entityType, timePeriod, }) => {
    const abortController = new AbortController();

    const request = axios({
        url: ApiUrls.GET_DASHBOARD_OPERATIONS_TOP_AGENTS,
        method: Methods.GET,
        signal: abortController.signal,
        params: {
            entityType,
            timePeriod,
            timeZone: dateService.getTimeZone()
        }
    })

    const cancelRequest = () => abortController.abort();

    return { request, cancelRequest };
}

export const getTotalsCardData = ({ currencyCode, betSlipFilterType, betShopNameOrId, timePeriod }) => {
    const abortController = new AbortController();

    const request = axios({
        url: ApiUrls.GET_DASHBOARD_CARDS,
        method: Methods.GET,
        signal: abortController.signal,
        params: {
            currencyCode,
            betSlipFilterType,
            betShopNameOrId,
            timePeriod,
            timeZone: dateService.getTimeZone()
        }
    })

    const cancelRequest = () => abortController.abort();

    return { request, cancelRequest };
}
