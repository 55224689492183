import React from 'react';

const Label = ({ label, children }) => {
    return (
        <div
            key={label}
            className={'rt--filters-item rt--mr-16 rt--mb-10'}
        >
            <div className='rt--mb-8'>
                <span className="rt--filters-item-title rt--title vs--font-regular vs--font-normal rt--font-capitalize">
                    {label}
                </span>
            </div>

            {children}
        </div>
    );
};

export default Label;
