import React, { Fragment, useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';

import Loader from "components/common/loader";

import { ORDER_DIRECTION } from 'constants/common.constants';

import { toUpperCaseFirstLetter, toLowerCaseFirstLetter, copyToClipboard } from 'utils/common';

import useFormat from 'hooks/useFormat';

import sortingType from 'types/sorting.type';

/** Table Component */
const Table = ({
    loadFn,
    defaultSortingColumn = "CreatedAt",
    setSortingFn,
    isLoading,
    data,
    sorting={},
    columns,
    actions,
    emptyImg,
    emptyText,
    name,
    enableReload,
    filters={},
    setFiltersFn,
    uniqueKey = "id",
    expandable,
    renderExpandContent
}) => {

    const { t } = useTranslation();

    const { formatNumber } = useFormat();

    const [expanded, setExpanded] = useState([]);

    /** Load table data */
    useEffect(() => {
        loadFn && loadFn()
    }, [])

    useEffect(() => {
        setExpanded([]);
    }, [data]);

    /** Function to call on sorting button click
       * @function
       * @param {string} column - column name to sort
       * @memberOf Table
   */
    const handleSorting = column => {
        const defaultSorting = defaultSortingColumn;
        const s = {
            page: sorting.page,
            limit: sorting.limit
        }

        if (toLowerCaseFirstLetter(sorting.orderBy) === toLowerCaseFirstLetter(getColumnSortingKey(column))) {
            if (sorting.orderDirection === ORDER_DIRECTION.DESC) {
                s.orderDirection = ORDER_DIRECTION.ASC;
                s.orderBy = sorting.orderBy;
            } else {
                s.orderDirection = ORDER_DIRECTION.DESC;
                s.orderBy = defaultSorting;
            }
        } else {
            s.orderDirection = ORDER_DIRECTION.DESC;
            s.orderBy = toUpperCaseFirstLetter(getColumnSortingKey(column))
        }
        setSortingFn(s);

        setTimeout(() => {
            loadFn && loadFn()
        }, 0)
    }

    /** Function to call on reload button click
       * @function
       * @memberOf Table
   */
    const reload = () => {
        // if (setFiltersFn && filters) {
        //     if (filters.to) {
        //         setFiltersFn({
        //             ...filters,
        //             to: moment(new Date(), `${config.DATE_FORMAT} ${config.TIME_FORMAT}`).toDate(),
        //         });
        //     }
        // }
        loadFn && loadFn();
    }

    /** Function to call on row click
       * @function
       * @param { string } key - row unique key
       * @memberOf Table
   */
    const handleRowClick = key => {
        if(!expandable) return;
        setExpanded(prevState => prevState.includes(key) ? prevState.filter(k => k !== key) : prevState.concat([key]))
    }

    const getColumnSortingKey = column => column.sortingKey ?? column.key

    const showLastColumn = actions?.length > 0 || enableReload

    return (
        <div className='rt--table' data-name={name}>
            {
                data.length > 0 ? (
                    <Fragment>
                        <div className='rt--table-row rt--table-row-header'>
                            <div className='rt--table-row-inner rt--flex rt--align-center rt--justify-start rt--text-left'>
                                {
                                    expandable && (
                                        <div className='rt--table-col rt--table-col-expand rt--flex rt--align-center rt--justify-center'>
                                        </div>
                                    )
                                }
                                {
                                    columns.map(column => (
                                        <div
                                            className='rt--table-col rt--flex rt--align-center rt--justify-start rt--pl-16'
                                            key={column.key}
                                            data-type={column.key}
                                        >
                                            <span title={column.title} className='rt--title rt--font-normal rt--font-regular rt--font-capitalize'>{column.title}</span>
                                            {
                                                column.sorting && (
                                                    <div
                                                        className={
                                                            'rt--flex rt--align-center rt--cursor-pointer' +
                                                            (
                                                                getColumnSortingKey(column) === toLowerCaseFirstLetter(sorting.orderBy) ?
                                                                    sorting.orderDirection === ORDER_DIRECTION.ASC ?
                                                                        " rt--table-col-sorter-asc" : " rt--table-col-sorter-desc" : ""
                                                            )
                                                        }
                                                        onClick={() => handleSorting(column)}
                                                    >
                                                        <i className='icon-sorting rt--font-bigest'></i>
                                                    </div>
                                                )
                                            }
                                        </div>
                                    ))
                                }
                                {
                                    showLastColumn && (
                                        <div
                                            className='rt--table-col rt--table-col-actions rt--flex rt--justify-center rt--align-center'
                                        >
                                            {
                                                enableReload && (
                                                    <i
                                                        className='icon-reload rt--font-bigest rt--cursor-pointer'
                                                        onClick={reload}
                                                        title={t("common.refreshData")}
                                                    />
                                                )
                                            }
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                        <div className='rt--table-list'>
                            {
                                isLoading ? (<Loader />) : data.map((d, index) => (
                                    <div
                                        className={
                                            'rt--table-row rt--mb-4' +
                                            (expandable ? " rt--table-row-with-expand" : "") +
                                            (expanded.includes(d[uniqueKey]) ? " rt--table-row-expanded" : "")
                                        }
                                        key={d[uniqueKey]}
                                    >
                                        <div
                                            className='rt--table-row-inner rt--flex rt--align-center rt--justify-start'
                                            onClick={() => handleRowClick(d[uniqueKey])}
                                        >
                                            {
                                                expandable && (
                                                    <div className='rt--table-col rt--table-col-expand rt--flex rt--align-center rt--justify-center'>
                                                        <i className='icon-down rt--font-bigest' />
                                                    </div>
                                                )
                                            }
                                            {
                                                columns.map(column => {
                                                    return (
                                                        <div
                                                            className='rt--table-col rt--flex rt--align-center rt--pl-16'
                                                            key={column.key} data-type={column.key}>
                                                            {
                                                                column.render ? (
                                                                    column.render(d[column.key], d, index)
                                                                ) : column.copy ? (
                                                                    <div
                                                                        className='rt--table-col-copy rt--flex rt--align-center'>
                                                                        <span
                                                                            className='rt--title rt--font-normal rt--font-regular'>{d[column.key]}</span>
                                                                        <i
                                                                            className='icon-copy rt--font-bigest rt--cursor-pointer'
                                                                            onClick={(event) => {
                                                                                event.stopPropagation();
                                                                                copyToClipboard(d[column.key]);
                                                                            }}
                                                                        />
                                                                    </div>
                                                                ) : column.isNumeric ? (
                                                                    <span
                                                                        className='rt--title rt--font-normal rt--font-regular'
                                                                        title={formatNumber(d[column.key])}>
                                                                            {
                                                                                formatNumber(d[column.key])
                                                                            }
                                                                        </span>
                                                                ) : (
                                                                    <span
                                                                        className='rt--title rt--font-normal rt--font-regular'
                                                                        title={d[column.key]}>{d[column.key]}</span>
                                                                )
                                                            }

                                                        </div>
                                                    );
                                                })
                                            }

                                            { showLastColumn && (
                                                    <div
                                                        className='rt--table-col rt--table-col-actions rt--flex rt--justify-center rt--align-center'
                                                    >
                                                        {
                                                            actions?.filter(ac => !ac.isHidden || !ac.isHidden(d)).map((ac, index) => (
                                                                <div
                                                                    className='rt--flex rt--align-center rt--justify-center rt--ml-8'
                                                                    key={index}
                                                                    onClick={() => ac.onClick(d)}
                                                                    title={ac.title ? ac.title(d) : ""}
                                                                >
                                                                    <i
                                                                        className={(typeof ac.icon === "function" ? ac.icon(d) : ac.icon) + " rt--font-bigest rt--cursor-pointer"}
                                                                    />
                                                                </div>

                                                            ))
                                                        }

                                                    </div>
                                                )
                                            }
                                        </div>

                                        {
                                            expandable && (
                                                <div className={'rt--table-row-expandable rt--pl-16 rt--pr-16 rt--pb-4' + (!expanded.includes(d[uniqueKey]) ? " rt--table-row-expandable-hidden" : "")} >
                                                    {
                                                        renderExpandContent(d)
                                                    }
                                                </div>
                                            )
                                        }
                                    </div>
                                ))
                            }
                        </div>
                    </Fragment>
                ) : isLoading ? <Loader /> : (
                    <div className='rt--table-empty rt--flex rt--flex-col rt--align-center rt--justify-center'>
                        <img alt={emptyText} src={emptyImg} />
                        <span className='rt--title rt--pt-8 rt--text-center rt--font-medium rt--font-extra-biger'>{emptyText}</span>
                    </div>
                )
            }

        </div>
    )
}

/** Table propTypes
    * PropTypes
*/
Table.propTypes = {
    /** Function to call to laod table data */
    loadFn: PropTypes.func,
    /** Function to set sorting details */
    setSortingFn: PropTypes.func,
    /** Default sorting column */
    defaultSortingColumn: PropTypes.string,
    /** Function to set filters */
    setFiltersFn: PropTypes.func,
    /** Sorting details of table */
    sorting: sortingType,
    /** Filters of table */
    filters: PropTypes.object,
    /** Is data loading */
    isLoading: PropTypes.bool,
    /** The array of items to show in table */
    data: PropTypes.arrayOf(PropTypes.object),
    /** The array of objects representing the structure of columns */
    columns: PropTypes.arrayOf(
        PropTypes.shape({
            /** Column title */
            title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
            /** Column property of data */
            dataIndex: PropTypes.string,
            /** Column content render function */
            render: PropTypes.func,
            /** Is column sortable */
            sorter: PropTypes.bool,
            /** Is Numeric Field */
            isNumeric: PropTypes.bool
        })
    ),
    /** Object, represents the info about actions in table */
    actions: PropTypes.array,
    /** The path of img to show when table is empty */
    emptyImg: PropTypes.string,
    /** The text to show when table is empty */
    emptyText: PropTypes.string,
    /** Table name */
    name: PropTypes.string,
    /** Option to enable reload button for table */
    enableReload: PropTypes.bool,
    /** The property to use for unique Key, by default is "ID" */
    uniqueKey: PropTypes.string,
    /** Is the row expendable */
    expandable: PropTypes.bool,
    /** Function to render expended row content */
    renderExpandContent: PropTypes.func
}

export default Table;
