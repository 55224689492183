import {useCallback} from "react";
import StatisticsFiltersDesktop from "components/manager/dashboard/tabs/statisticsTab/filters/desktop";


const StatisticsFilters = ({ filters, currencyOptions, onChange }) => {
    const globalFilters = filters.global;

    const handleFiltersChange = useCallback((value) => {
        if (value.betShopNameOrId) {
            value = { ...value };
        }

        onChange('global', value);
    }, [onChange]);

    return <StatisticsFiltersDesktop filters={globalFilters} onChange={handleFiltersChange} currencyOptions={currencyOptions} />;
};

export default StatisticsFilters;
