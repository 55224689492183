import React, {useMemo} from 'react';

import {connect} from "react-redux";
import {useTranslation} from "react-i18next";

import useFormat from "hooks/useFormat";

import getColumns from "components/common/voucherHistory/helpers/getColumns";

import Table from "components/common/table";
import Pagination from "components/common/pagination";

import noDataImg from "assets/images/noContent.png";

import {
    getVoucherHistory, getVoucherHistoryTotals,
    setVoucherHistoryFilters,
    setVoucherHistorySorting
} from "store/actions/dashboard/voucherHisrory.action";
import VoucherHistoryTotals from "components/common/voucherHistory/totals";
import {getUser} from "utils/auth";
import {USER_ROLE} from "constants/user.constants";
import Totals from "components/common/totals";

const VoucherHistoryTable = ({ userInfo, vouchers, isLoading, total, totals, sorting, filters, getVoucherHistory, getVoucherHistoryTotals, setVoucherHistoryFilters, setVoucherHistorySorting }) => {
    const { t } = useTranslation();
    const { formatAmount } = useFormat();

    const isCashier = getUser()?.role === USER_ROLE.CASHIER;

    const { reprintVoucher } = userInfo;

    const columns = useMemo(() => getColumns({ t, formatAmount, isCashier, reprintVoucher }), [t, formatAmount, isCashier, reprintVoucher]);

    const mappedTotals = useMemo(() =>
        totals?.reduce((acc, current) => {
            acc[current.currencyCode] = { amount: current.amount };

            return acc;
        }, {}), [totals]);

    const totalsColumns = [
        {
            key: "amount",
            title: t("common.amount"),
        },
        {
            key: "currencyCode",
            title: t("common.currency"),
        },
    ];

    return (
        <>
            {!isCashier && <Totals
                columns={totalsColumns}
                totals={mappedTotals}
                isLoading={false}
                loadFn={getVoucherHistoryTotals}
            />}

            <div className="rt--flex-1 rt--min-h-0">
                <Table
                    name={isCashier ? "voucherHistoryCashier": "voucherHistoryManager"}
                    loadFn={() => {
                        getVoucherHistory()
                    }}
                    columns={columns.filter(Boolean)}
                    data={vouchers}
                    isLoading={isLoading}
                    sorting={sorting}
                    emptyImg={noDataImg}
                    emptyText={t("common.noVoucherFound")}
                    setFiltersFn={f => setVoucherHistoryFilters(f)}
                    setSortingFn={setVoucherHistorySorting}
                    filters={filters}
                    uniqueKey="id"
                />
            </div>

            {isCashier && <VoucherHistoryTotals name="voucherHistory" columns={columns}/>}

            <Pagination
                total={total}
                onChange={value => setVoucherHistorySorting({
                    ...value
                })}
                page={sorting.page}
                limit={sorting.limit}
                loadFn={() => getVoucherHistory()}
            />
        </>
    );
};

const mapStateToProps = state => ({
    isLoading: state.voucherHistory.isLoading,
    sorting: state.voucherHistory.sorting,
    vouchers: state.voucherHistory.vouchers,
    total: state.voucherHistory.total,
    totals: state.voucherHistory.totals,
    filters: state.voucherHistory.filters,
    userInfo: state.profile.userInfo
})

const mapDispatchToProps = dispatch => ({
    getVoucherHistory: () => dispatch(getVoucherHistory()),
    getVoucherHistoryTotals: () => dispatch(getVoucherHistoryTotals()),
    setVoucherHistoryFilters: (filters) => dispatch(setVoucherHistoryFilters(filters)),
    setVoucherHistorySorting: (filters) => dispatch(setVoucherHistorySorting(filters)),
})

export default connect(mapStateToProps, mapDispatchToProps)(VoucherHistoryTable);
