import {useTranslation} from "react-i18next";
import {TIME_PERIOD} from "components/manager/dashboard/constants";
import {Select} from "antd";
import React, {useEffect, useMemo} from "react";
import {getManagerAvailableBetshops} from "store/actions/dashboard/managerBetshops.action";
import {connect, useSelector} from "react-redux";

const StatisticsFiltersDesktop = ({ filters, onChange, currencyOptions, getManagerAvailableBetshops }) => {
    const betshopNames = useSelector(state => state.managerBetshops.availableBetshops);

    const { t } = useTranslation();

    useEffect(() => {
        getManagerAvailableBetshops();
    }, []);

    const betShopOptions = useMemo(() => ([
        {
            label: t('common.allBetshops'),
            value: ''
        },
        ...betshopNames.map(betshop => ({
            label: betshop.name,
            value: betshop.id
        }))
    ]), [betshopNames, t]);

    return (
        <div className="rt--flex rt--gap-8">
            <Select
                className='rt--select'
                style={{width: '200px'}}
                value={filters.currencyCode}
                options={[
                    ...currencyOptions
                ]}
                onChange={(value) => onChange({"currencyCode": value})}
            />
            {/*<div*/}
            {/*    key="ajkshdhjaskd"*/}
            {/*    className={'rt--filters-item rt--mr-16 rt--mb-10'}*/}
            {/*>*/}
            {/*    <div className='rt--mb-8'>*/}
            {/*        <span className="rt--filters-item-title rt--title vs--font-regular vs--font-normal rt--font-capitalize">*/}
            {/*            asdasd*/}
            {/*        </span>*/}
            {/*    </div>*/}
            <Select
                    className='rt--select'
                    style={{width: '200px'}}
                    placeholder={t('common.allBetshops')}
                    options={betShopOptions}
                    value={filters.betShopNameOrId}
                    onChange={value => onChange({ betShopNameOrId: value })}
                    search
                />
            {/*</div>*/}

            <Select
                options={
                    [
                        { value: TIME_PERIOD.TODAY, label: t("common.today") },
                        { value: TIME_PERIOD.YESTERDAY, label: t("common.yesterday") },
                        { value: TIME_PERIOD.THIS_WEEK, label: t("common.thisWeek") },
                        { value: TIME_PERIOD.LAST_WEEK, label: t("common.lastWeek") },
                        { value: TIME_PERIOD.THIS_MONTH, label: t("common.thisMonth") },
                        { value: TIME_PERIOD.LAST_MONTH, label: t("common.lastMonth") },
                        { value: TIME_PERIOD.LAST_QUARTER, label: t("common.lastQuarter") },
                        { value: TIME_PERIOD.THIS_YEAR, label: t("common.ytd") },
                        { value: TIME_PERIOD.LAST_YEAR, label: t("common.lastYear") },
                    ]
                }
                className='rt--select'
                style={{width: '200px'}}
                onChange={value => onChange({ timePeriod: value })}
                value={filters.timePeriod}
            />

        </div>
    );
};

const mapDispatchToProps = (dispatch) => ({
    getManagerAvailableBetshops: () => {
        dispatch(getManagerAvailableBetshops())
    },
});

export default connect(null, mapDispatchToProps)(StatisticsFiltersDesktop);
