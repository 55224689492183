import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { Input } from "antd";

import Filters from "components/common/filters";
import Export from "components/common/export";

import { setTransactionsFilters, getTransactions, resetTransactions } from "store/actions/dashboard/transactions.action";
import { getManagerAvailableBetshops } from "store/actions/dashboard/managerBetshops.action";
import { getManagerAvailableCashiers } from "store/actions/dashboard/cashiers.action";

import { getUser } from 'utils/auth';
import { isTerminalProject } from "utils/access";
import { getExportFilters } from "components/common/transactions/filters/helpers";

import { DATE_PICKER_RANGES, TRANSACTION_TYPE, WALLET_OWNER_TYPE } from "constants/common.constants";
import { USER_ROLE } from 'constants/user.constants';
import ApiUrls from "constants/api.constants";

import managerAvailableBetshopType from "types/managerAvailableBetshop.type";
import autoSuggestionType from "types/autoSuggestion.type";

import userInfoType from 'types/userInfo.type';


let timer = null;

/** Transactins Table Filters Component */
const TransactionsFilters = ({
    type,
    setTransactionsFilters,
    getTransactions,
    resetTransactions,
    filters,
    getManagerAvailableBetshops,
    getManagerAvailableCashiers,
    availableBetshops,
    availableCashiers,
    columns,
    userInfo
}) => {

    const { t } = useTranslation();

    const [opened, setOpened] = useState(false);

    const [searchValue, setSearchValue] = useState(filters[type].transactionId);

    const title = type === WALLET_OWNER_TYPE.CASHIER ? t("common.cashierTransactions") : t("common.betshopTransactions");
    const mappedFilters = {
        from: filters[type].from,
        to: filters[type].to,
        betShopId: filters[type].betShopId,
        period: filters[type].period,
        transactionTypes: filters[type].transactionTypes,
        cashier: filters[type].cashier,
        ...(
            type === WALLET_OWNER_TYPE.CASHIER ? {
                player: filters[type].player
            } : {}
        )
    }

    const exportFilters = getExportFilters(type, mappedFilters, isTerminalProject(userInfo.projectType));

    const exportColumns = [
        ...columns,
        { title: t("common.id"), key: "id" },
        { title: t("common.notes"), key: "notes" },
        { title: t("common.ownerType"), key: "ownerType" },
        { title: t("common.walletType"), key: "walletType" },
        { title: t("common.before"), key: "before" },
        { title: t("common.after"), key: "after" },
        { title: t("common.ownerId"), key: "ownerId" },
        { title: t("common.ownerName"), key: "ownerName" },
        { title: t("common.userId"), key: "userId" },
        { title: t("common.username"), key: "userName" },
    ]

    /** Reset transactions data */
    useEffect(() => () => {
        resetTransactions(type)
    }, [])

    /** Load available betshops and cashiers for betshop manager */
    useEffect(() => {
        if(getUser()?.role === USER_ROLE.MANAGER){
            getManagerAvailableBetshops();
            getManagerAvailableCashiers();
        }
    }, [])

    /** Function, handler for transactionId search input
		 * @function
		 * @param {string} value - the field value
		 * @memberOf TransactionsFilters
    */
    const handleInputChange = value => {

        const reg = new RegExp('^[0-9]*$');

        if (!isNaN(value) && reg.test(value) || value === '') {
            setSearchValue(value);
            clearTimeout(timer)
            timer = setTimeout(() => {
                if(!value || value.length > 2){
                    setTransactionsFilters({
                        transactionId: value ?? ""
                    }, type)
                    setTimeout(() => {
                        getTransactions(type)
                    }, 0)
                }
            }, 300)
        }

    }

    const transactionTypes = useMemo(() => {
        let types = [];
        if(type === WALLET_OWNER_TYPE.CASHIER){
            types = [
                {value: TRANSACTION_TYPE.BET, title: t("common.bet")},
                {value: TRANSACTION_TYPE.CANCEL_BET, title: t("common.cancelBet")},
                {value: TRANSACTION_TYPE.PAIDOUT, title: t("common.payout")},
                {value: TRANSACTION_TYPE.WON, title: t("common.won")},
                {value: TRANSACTION_TYPE.REJECT_BET, title: t("common.rejected")},
                {value: TRANSACTION_TYPE.RECALCULATE, title: t("common.recalculate")},
                {value: TRANSACTION_TYPE.PLAYER_DEPOSIT, title: t("common.depositToPlayer")},
                {value: TRANSACTION_TYPE.PLAYER_WITHDRAWAL, title: t("common.withdrawFromPlayer")},
                {value: TRANSACTION_TYPE.BETSHOP_CASHIER_HANDOVER_CASHIER, title: t("common.shiftHandover")},
            ];
            if (isTerminalProject(userInfo.projectType)) {
                types.push( {value: TRANSACTION_TYPE.ISSUE_VOUCHER, title: t("common.issueVoucher")} );
                types.push( {value: TRANSACTION_TYPE.PAYOUT_VOUCHER, title: t("common.payoutVoucher")} );
                types.push( {value: TRANSACTION_TYPE.TERMINAL_CASH_REMOVAL, title: t("common.terminalCashRemoval")} );
            }
        } else {
            types = [
                {value: TRANSACTION_TYPE.BETSHOP_MANAGER_DEPOSIT_CASHIER, title: t("common.depositToCashier")},
                {value: TRANSACTION_TYPE.BETSHOP_MANAGER_WITHDRAW_CASHIER, title: t("common.withdrawFromCashier")},
                {value: TRANSACTION_TYPE.BETSHOP_LIMIT_AUTO_ADJUSTMENT, title: t("common.betShopLimitAutoAdjustment")},
                {value: TRANSACTION_TYPE.BETSHOP_LIMIT_MANUAL_ADJUSTMENT, title: t("common.betShopLimitManualAdjustment")}
            ];

            if (isTerminalProject(userInfo.projectType)) {
                types.push( {value: TRANSACTION_TYPE.TERMINAL_CASH_REMOVAL, title: t("common.terminalCashRemoval")} );
            }
        }
        return types;
    }, [type, userInfo, t])

    return (
        <div className='rt--filters-header rt--mt-32 rt--pb-16 rt--mb-30'>
            <div className='rt--flex rt--justify-between rt--align-center'>
                <div className='rt--flex rt--align-center rt--filters-header-title'>
                    <span className='rt--title rt--font-big rt--font-regular'>
                        {title}
                    </span>
                </div>

                <div className='rt--flex'>
                    <div className='rt--filters-header-search'>
                        <Input
                            placeholder={t("common.searchByTransactionId")}
                            onChange={e => handleInputChange(e.target.value)}
                            value={searchValue}
                            maxLength={12}
                            allowClear={true}
                        />
                        <i className='icon-search rt--font-bigest'></i>
                    </div>

                    <div className="rt--flex rt--align-center">
                        <Export
                            filters={exportFilters}
                            title={title}
                            columns={exportColumns}
                            url={ApiUrls.EXPORT_BETSHOP_TRANSACTIONS}
                            tableName={title}
                            isPostRequest
                        />

                        <button
                            className='rt--button rt--button-secondary rt--ml-16'
                            onClick={() => setOpened(!opened)}
                        >
                            <span className='rt--flex rt--justify-center rt--align-center'>
                                <i className='icon-filter rt--font-bigest'></i>
                                <span className='rt--title rt--font-medium rt--font-normal'>{t("common.filters")}</span>
                            </span>
                        </button>
                    </div>
                </div>
            </div>
            <Filters
                loadFn={() => getTransactions(type)}
                setFiltersFn={ f => setTransactionsFilters(f, type) }
                filters={mappedFilters}
                useDateWithTime={true}
                controls={[
                    {
                        title: t('common.timePeriod'),
                        name: "period",
                        type: "SELECT",
                        small: true,
                        items: [
                            { value: DATE_PICKER_RANGES.TODAY, title: t('common.today') },
                            { value: DATE_PICKER_RANGES.YESTERDAY, title: t('common.yesterday') },
                            { value: DATE_PICKER_RANGES.THIS_MONTH, title: t('common.thisMonth') },
                            { value: DATE_PICKER_RANGES.LAST_MONTH, title: t('common.lastMonth') },
                            { value: DATE_PICKER_RANGES.LAST_3_MONTH, title: t('common.3Months') },
                        ]
                    },
                    {
                        title: t('common.dateRange'),
                        name: "date",
                        type: "RANGEPICKER",
                    },
                    {
                        title: t('common.transactionType'),
                        name: "transactionTypes",
                        type: "SELECT",
                        items: [
                            { value: "", title: t('common.all') },
                            ...transactionTypes
                        ],
                        small: type === WALLET_OWNER_TYPE.CASHIER,
                    },
                    ...(
                        getUser()?.role === USER_ROLE.MANAGER ? [
                            {
                                title: t('common.betshop'),
                                name: "betShopId",
                                type: "SELECT",
                                items: [
                                    { value: "", title: t('common.all') },
                                    ...availableBetshops.map(b => ({
                                        value: b.id, title: b.name
                                    }))
                                ],
                                onChange: (value, updateForm) => {
                                    setTransactionsFilters({
                                        cashier: ""
                                    }, type)
                                    updateForm("cashier", "")
                                    getManagerAvailableCashiers(value)
                                },
                                small: true,
                            },
                            {
                                title: t('common.cashier'),
                                name: "cashier",
                                type: "SELECT",
                                items: [
                                    { value: "", title: t('common.all') },
                                    ...availableCashiers.map(b => ({
                                        value: b.id, title: b.name
                                    }))
                                ],
                                small: true,
                            },
                        ] : []
                    ),
                    ... (
                        type === WALLET_OWNER_TYPE.CASHIER ? [
                            {
                                title: t('common.playerUsernameOrId'),
                                name: "player",
                                type: "INPUT",
                                placeholder: `${t('common.enter')} ${t('common.playerUsernameOrId')}`
                            }
                        ] : []
                    )
                ]}
                visible={opened}
            />
        </div>

    )
}

/** TransactionsFilters propTypes
    * PropTypes
*/
TransactionsFilters.propTypes = {
    /** Transactions owner type */
    type: PropTypes.oneOf(Object.values(WALLET_OWNER_TYPE)),
    /** Redux action to get transactions */
    getTransactions: PropTypes.func,
    /** Redux action to set transactions filters */
    setTransactionsFilters: PropTypes.func,
    /** Redux action to reset transactions */
    resetTransactions: PropTypes.func,
    /** Redux action to get available betshops for manager */
    getManagerAvailableBetshops: PropTypes.func,
    /** Redux action to get available cashiers for manager */
    getManagerAvailableCashiers: PropTypes.func,
    /** Redux state property, transactions filters */
    filters: PropTypes.object,
    /** Redux state property, available betshops for manager */
    availableBetshops: PropTypes.arrayOf(managerAvailableBetshopType),
    /** Redux state property, available cashiers for manager */
    availableCashiers: PropTypes.arrayOf(autoSuggestionType),
    /** Table Columns */
    columns: PropTypes.arrayOf(PropTypes.object),
    /** Redux state property, current user info */
    userInfo: userInfoType
}

const mapDispatchToProps = dispatch => (
    {
        getTransactions: type => {
            dispatch(getTransactions(type));
        },

        setTransactionsFilters: (filters, type)=> {
            dispatch(setTransactionsFilters(filters, type));
        },

        resetTransactions: type => {
            dispatch(resetTransactions(type));
        },

        getManagerAvailableBetshops: () => {
            dispatch(getManagerAvailableBetshops())
        },

        getManagerAvailableCashiers: id => {
            dispatch(getManagerAvailableCashiers(id))
        }
    }
)

const mapStateToProps = state => {
    return {
        filters: state.transactions.filters,
        availableBetshops: state.managerBetshops.availableBetshops,
        availableCashiers: state.cashiers.availableCashiers,
        userInfo: state.profile.userInfo
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TransactionsFilters);
