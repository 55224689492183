import React from 'react';

const StateTag = ({ color, children }) => {
    return (
        <div style={{ "--state-tag-color": color }} className="rt--state-tag">
            <span className="rt--state-tag-dot" />

            {children}
        </div>
    );
};

export default StateTag;
