import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Modal, Input, Select, Form } from 'antd';

import Loader from "components/common/loader";
import PrintTransferTicket from "components/common/tickets/transferTicket"

import DepositForm from "./deposit";
import WithdrawForm from "./withdraw";
import FailedTransactions from "./failedTransactions";

import { getPlayerInfo, depositToPlayer, withdrawPlayer } from 'store/actions/dashboard/players.action';

import { POPUP_SIZE } from "constants/popup.constants";
import { PLAYER_TRANSACTION_TYPE, PLAYER_STATE, PLAYER_FIND_BY, ENVIRONMENT_TYPE } from 'constants/common.constants';
import { USERNAME_REGEX, TEL_REGEX, EMAIL_REGEX } from 'constants/regex.constants';
import { PLAYER_DOCUMENT_TYPE } from 'constants/registrationForm.constants';

import { getUser } from 'utils/auth';

import playerInfoType from 'types/playerInfo.type';

import NoTicketImg from "assets/images/noTicket.png";

const STEPS = {
    SEARCH: 1,
    FORM: 2,
    NORESULT: 3,
    FAILED: 4
}

/** Transfer Player Popup Component */

const TransferPlayerPopupComponent = ({
    handleCloseModal,
    transferType,
    isLoading,
    getPlayerInfo,
    playerInfo,
    depositToPlayer,
    withdrawPlayer
}) => {

    const { t } = useTranslation();

    /** Current Step */
    const [currentStep, setStep] = useState(STEPS.SEARCH);

    const [formInstance] = Form.useForm();
    const { validateFields, setFieldsValue } = formInstance;

    /** Form current values */
    const [formValues, setFormValues] = useState({ findBy: PLAYER_FIND_BY.ID });
    /** Transfer form values */
    const [transferFormValues, setTransferFormValues] = useState({});
    /** Failed transactions array */
    const [failedTransactions, setFailedTransactions] = useState([]);

    /** Flag to print ticket */
    const [printTransferTicket, setPrintTransferTicket] = useState(null)

    /** Function, to call on search button click
		 * @function
		 * @memberOf TransferPlayerPopupComponent
    */
    const handleSearch = () => {
        validateFields()
            .then(data => {
                const d = {};
                switch (data.findBy) {
                    case PLAYER_FIND_BY.ID:
                        d["externalId"] = data.field;
                        break;
                    case PLAYER_FIND_BY.USERNAME:
                        d["userName"] = data.field;
                        break;
                    case PLAYER_FIND_BY.EMAIL:
                        d["email"] = data.field;
                        break;
                    case PLAYER_FIND_BY.MOBILE:
                        d["mobile"] = data.field;
                        break;
                    default:
                        break;
                }

                getPlayerInfo(d, data => {
                    if (data) {
                        setStep(STEPS.FORM)
                    } else {
                        setStep(STEPS.NORESULT)
                    }
                })
            }).catch(() => { })
    }

    /** Function, to detect if search button should be disabled
		 * @function
         * @returns {boolean}
		 * @memberOf TransferPlayerPopupComponent
    */
    const isSearchDisabled = () => {
        if(!formValues.field){
            return true;
        }

        let regex;
        switch (formValues.findBy) {
            case PLAYER_FIND_BY.ID:
                break;
            case PLAYER_FIND_BY.USERNAME:
                regex = RegExp(USERNAME_REGEX);
                break;
            case PLAYER_FIND_BY.EMAIL:
                regex = RegExp(EMAIL_REGEX);
                break;
            case PLAYER_FIND_BY.MOBILE:
                regex = RegExp(TEL_REGEX);
                break;
            default:
                break;
        }
        return regex && !regex.test(formValues.field)
    }

    /** Function, to make search field label
		 * @function
         * @returns {string}
		 * @memberOf TransferPlayerPopupComponent
    */
    const makeSearchFieldLabel = () => {
        switch (formValues["findBy"]) {
            case PLAYER_FIND_BY.ID:
                return t("common.playerId");
            case PLAYER_FIND_BY.USERNAME:
                return t("common.username");
            case PLAYER_FIND_BY.EMAIL:
                return t("common.email");
            case PLAYER_FIND_BY.MOBILE:
                return t("common.phoneNumber");
            default:
                return ""
        }
    }

    /** Function, to get form validation rules
		 * @function
         * @returns {array}
		 * @memberOf TransferPlayerPopupComponent
    */
    const getSearchFieldValidationRules = () => {
        const rules = [
            { required: true, whitespace: true, message: t('validation.field_required') },
        ];
        switch (formValues["findBy"]) {
            case PLAYER_FIND_BY.ID:
                rules.push({ max: 100, message: t('validation.field_invalid') });
                break;
            case PLAYER_FIND_BY.USERNAME:
                rules.push({ pattern: USERNAME_REGEX, message: t('validation.field_invalid') });
                break;
            case PLAYER_FIND_BY.EMAIL:
                rules.push({ pattern: EMAIL_REGEX, message: t('validation.field_invalid') });
                break;
            case PLAYER_FIND_BY.MOBILE:
                rules.push({ pattern: TEL_REGEX, message: t('validation.field_invalid') });
                break;
            default:
                break;
        }
        return rules;
    }

    /** Function, to render popup content
		 * @function
         * @param {number} step - step
         * @returns {JSX}
		 * @memberOf TransferPlayerPopupComponent
    */
    const renderContent = step => {
        switch (step) {
            case STEPS.SEARCH:
            case STEPS.NORESULT:
                return !isLoading ? (
                    <Form
                        className="rt--form"
                        form={formInstance}
                        colon={false}
                        hideRequiredMark={true}
                        layout="vertical"
                        initialValues={{
                            findBy: PLAYER_FIND_BY.ID
                        }}
                        onValuesChange={(_, formValues) => setFormValues(formValues)}
                    >
                        <div>
                            {
                                (
                                    transferType === PLAYER_TRANSACTION_TYPE.WITHDRAW &&
                                    getUser()?.environmentType === ENVIRONMENT_TYPE.RETAIL
                                ) && (
                                    <Form.Item
                                        className='rt--modal-form-item rt--flex rt--flex-col'
                                        label={
                                            (
                                                <span className='rt--title rt--font-regular rt--font-normal'>{t("common.securityCode")}</span>
                                            )
                                        }
                                        name="securityCode"
                                    >
                                        <Input
                                            className='rt--input'
                                            placeholder={`${t("common.enter")} ${t("common.securityCode")}`}
                                        />
                                    </Form.Item>
                                )
                            }

                            <Form.Item
                                className='rt--modal-form-item rt--flex rt--flex-col'
                                label={
                                    (
                                        <span className='rt--title rt--font-regular rt--font-normal'>{t("common.findBy")}</span>
                                    )
                                }
                                name="findBy"
                                rules={[]}
                                validateFirst
                            >
                                <Select
                                    placeholder={`${t('common.select')} ${t("common.findBy")}`}
                                    showSearch={false}
                                    optionFilterProp="children"
                                    suffixIcon={<i className="icon-down rt--font-bigest"></i>}
                                    getPopupContainer={trigger => trigger.parentNode}
                                    className="rt--select"
                                    onChange={() => setFieldsValue({field : ""})}
                                >
                                    <Select.Option value={PLAYER_FIND_BY.ID}>{t("common.playerId")}</Select.Option>
                                    <Select.Option value={PLAYER_FIND_BY.USERNAME}>{t("common.username")}</Select.Option>
                                    {
                                        getUser()?.environmentType === ENVIRONMENT_TYPE.RETAIL && (
                                            <Select.Option value={PLAYER_FIND_BY.EMAIL}>{t("common.email")}</Select.Option>
                                        )
                                    }
                                    {
                                        getUser()?.environmentType === ENVIRONMENT_TYPE.RETAIL && (
                                            <Select.Option value={PLAYER_FIND_BY.MOBILE}>{t("common.phoneNumber")}</Select.Option>
                                        )
                                    }
                                </Select>
                            </Form.Item>

                            <Form.Item
                                className='rt--modal-form-item rt--flex rt--flex-col'
                                label={
                                    (
                                        <span className='rt--title rt--font-regular rt--font-normal'>{`${makeSearchFieldLabel()} *`}</span>
                                    )
                                }
                                name="field"
                                rules={getSearchFieldValidationRules()}
                                validateFirst
                            >
                                <Input
                                    placeholder={`${t('common.select')} ${makeSearchFieldLabel()}`}
                                    className="rt--input"
                                />
                            </Form.Item>

                            <div className='rt--flex rt--justify-end'>
                                <button
                                    className={"rt--button-complimentary rt--button rt--pl-16 rt--pr-16" + ((isSearchDisabled()) ? " rt--button-disabled" : "")}
                                    onClick={handleSearch}
                                    disabled={isSearchDisabled()}
                                >
                                    <span className='rt--title rt--font-normal rt--font-regular'>{t('common.search')}</span>
                                </button>
                            </div>

                            {
                                step === STEPS.NORESULT && (
                                    <div className='rt--flex rt--flex-col rt--align-center rt--justify-center rt--pt-54 rt--pb-54'>
                                        <img alt="icon" src={NoTicketImg} />
                                        <b className='rt--title rt--font-normal rt--font-medium rt--pt-8 rt--pb-8 rt--modal-transfer-player-search-info'>{t("common.playerNotFound")}</b>
                                        <span className="rt--title rt--font-normal rt--font-regular rt--modal-transfer-player-search-info-desc">{t("common.pleaseMakeSureThePlayerIdIsCorrect")}</span>
                                    </div>
                                )
                            }

                        </div>
                    </Form>
                ) : <Loader />;
            case STEPS.FORM:
                return (
                    <div className='rt--modal-transfer-player-view'>
                        <div
                            className='rt--flex rt--align-center rt--modal-transfer-player-view-back rt--mb-16'
                            onClick={() => setStep(STEPS.SEARCH)}
                        >
                            <i
                                className='icon-left rt--font-big rt--mr-4 rt--cursor-pointer'
                            />
                            <div className='rt--flex rt--flex-col'>
                                <span className='rt--title rt--font-normal rt--font-medium'>
                                    {t("common.back")}
                                </span>
                            </div>
                        </div>

                        <div className='rt--modal-transfer-player-info rt--pl-16 rt--pr-16 rt--pt-16 rt--pb-16 rt--mb-16'>
                            <div className='rt--flex rt--align-center'>
                                <div className='rt--modal-transfer-player-info-avatar rt--flex rt--align-center rt--justify-center rt--mr-8'>
                                    <i className='icon-user rt--font-bigest' />
                                </div>
                                <span className='rt--title rt--font-normal rt--font-medium'>
                                    {`${playerInfo.firstName || ""} ${playerInfo.lastName || ""}`}
                                </span>
                            </div>
                            <div className='rt--flex rt--align-center rt--modal-transfer-player-info-list rt--mt-16'>
                                <div className='rt--flex rt--flex-col rt--flex-equal'>
                                    <b className='rt--title rt--font-small rt--font-regular rt--font-capitalize rt--pb-4'>{t("common.username")}</b>
                                    <span className='rt--title rt--font-normal rt--font-regular'>{playerInfo.userName}</span>
                                </div>
                                <div className='rt--flex rt--flex-col rt--flex-equal'>
                                    <b className='rt--title rt--font-small rt--font-regular rt--font-capitalize rt--pb-4'>{t("common.currency")}</b>
                                    <span className='rt--title rt--font-normal rt--font-regular'>{playerInfo.currencyCode}</span>
                                </div>
                            </div>
                            <div className='rt--flex rt--align-center rt--modal-transfer-player-info-list rt--mt-16'>
                                <div className='rt--flex rt--flex-col rt--flex-equal'>
                                    <b className='rt--title rt--font-small rt--font-regular rt--font-capitalize rt--pb-4'>{t("common.status")}</b>
                                    <div className='rt--flex'>
                                        <div
                                            className="rt--modal-transfer-player-info-status rt--flex rt--align-center rt--pl-8 rt--pr-8"
                                            data-color={
                                                playerInfo.state === PLAYER_STATE.ACTIVE ? "green" :
                                                    (playerInfo.state === PLAYER_STATE.PARTIALY_BLOCKED || playerInfo.state === PLAYER_STATE.BLOCKED) ? "red" :
                                                        playerInfo.state === PLAYER_STATE.UNKNOWN ? "orange" : ""
                                            }
                                        >
                                            <span className="rt--title rt--font-normal rt--font-regular rt--font-capitalize">
                                                {
                                                    playerInfo.state === PLAYER_STATE.ACTIVE ? t("common.active") :
                                                        playerInfo.state === PLAYER_STATE.PARTIALY_BLOCKED ? t("common.partiallyBlocked") :
                                                            playerInfo.state === PLAYER_STATE.BLOCKED ? t("common.blocked") :
                                                                playerInfo.state === PLAYER_STATE.UNKNOWN ? t("common.unknown") : ""
                                                }
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className='rt--flex rt--flex-col rt--flex-equal'>
                                    <b className='rt--title rt--font-small rt--font-regular rt--font-capitalize rt--pb-4'>{t("common.currentBalance")}</b>
                                    <span className='rt--title rt--font-normal rt--font-regular'>{playerInfo.balance}</span>
                                </div>
                            </div>
                            {
                                playerInfo.documents && playerInfo.documents.length > 0 ? (
                                    <div className='rt--flex rt--align-center rt--modal-transfer-player-info-list rt--mt-16'>
                                        {
                                            playerInfo.documents.map(document => (
                                                <div className='rt--flex rt--flex-col rt--flex-equal' key={document.type}>
                                                    <b className='rt--title rt--font-small rt--font-regular rt--pb-4'>
                                                        {
                                                            document.type === PLAYER_DOCUMENT_TYPE.PASSPORT ?
                                                            t("common.passport") : document.type === PLAYER_DOCUMENT_TYPE.ID ? 
                                                            t("common.passportID") : t("common.drivingLicences")
                                                        }
                                                    </b>
                                                    <span className='rt--title rt--font-normal rt--font-regular'>{document.number}</span>
                                                </div>
                                            ))
                                        }
                                        
                                    </div>
                                ) : null
                            }
                        </div>

                        {
                            transferType === PLAYER_TRANSACTION_TYPE.DEPOSIT ? (
                                <DepositForm
                                    setFormValues={setTransferFormValues}
                                />
                            ) : <WithdrawForm
                                    setFormValues={setTransferFormValues}
                                    verificationCode={formValues.securityCode}
                                />
                        }
                    </div>
                )
        }
    }

    /** Function, to call on transfer button click
		 * @function
		 * @memberOf TransferPlayerPopupComponent
    */
    const doTransfer = () => {
        if (transferType === PLAYER_TRANSACTION_TYPE.DEPOSIT) {
            depositToPlayer({
                ...transferFormValues,
                playerId: playerInfo.id
            }, (success, transactions) => {
                if(success){
                    setPrintTransferTicket(transactions[0].changeAmount);
                    setTimeout(handleCloseModal, 300)
                } else {
                    setStep(STEPS.FAILED);
                    setFailedTransactions(transactions);
                }
            })
        } else {
            withdrawPlayer({
                ...transferFormValues,
                playerId: playerInfo.id,
                verificationCode: transferType === PLAYER_TRANSACTION_TYPE.WITHDRAW && getUser()?.environmentType === ENVIRONMENT_TYPE.RETAIL ? formValues.securityCode : undefined
            }, (success, transactions) => {
                if(success){
                    setPrintTransferTicket(transactions[0].changeAmount)
                    setTimeout(handleCloseModal, 300)
                } else {
                    setStep(STEPS.FAILED);
                    setFailedTransactions(transactions);
                }
            })
        }
    }

    return  currentStep === STEPS.FAILED ? (
        <FailedTransactions 
            transactions={failedTransactions} 
            onClose={handleCloseModal}
        />
    ) : (
        <Modal
            className='rt--modal rt--modal-withdrawals'
            title={(
                <div className='rt--flex rt--justify-between rt--align-center'>
                    <span className='rt--title rt--font-normal rt--font-regular'>
                        {
                            transferType === PLAYER_TRANSACTION_TYPE.DEPOSIT ? t("common.depositPlayer") : t("common.withdrawPlayer")
                        }
                    </span>
                    <i className='icon-close rt--font-bigest rt--cursor-pointer' onClick={() => handleCloseModal()} />
                </div>
            )}
            visible={true}
            onCancel={() => handleCloseModal()}
            closable={false}
            maskClosable={false}
            onOk={doTransfer}
            cancelButtonProps={{ className: 'rt--button-secondary' }}
            okButtonProps={{ 
                className: 'rt--button-primary', 
                disabled: currentStep === STEPS.FORM ? !transferFormValues.amount || isLoading : false
            }}
            cancelText={t('common.cancel')}
            okText={currentStep === STEPS.FORM ? transferType === PLAYER_TRANSACTION_TYPE.DEPOSIT ? t("common.deposit") : t("common.withdraw") : t('common.done')}
            getContainer={() => document.getElementById('rt--modal-root')}
            width={POPUP_SIZE.SMALL}
            centered
            {...(currentStep === STEPS.FORM ? {} : { footer: null })}
        >

            <div className='rt--modal-transfer-player'>
                {isLoading ? <Loader /> : renderContent(currentStep)}
            </div>
            <div className='rt--display-none'>
                {
                    printTransferTicket && (
                        <PrintTransferTicket 
                            amount={printTransferTicket}
                            transferType={transferType}
                        />
                    )
                }
            </div>
        </Modal>
    )

}

/** TransferPlayerPopupComponent propTypes
    * PropTypes
*/
TransferPlayerPopupComponent.propTypes = {
    /** Function to close popup */
    handleCloseModal: PropTypes.func,
    /** Transfer type(Deposit/Withdraw) */
    transferType: PropTypes.oneOf(Object.values(PLAYER_TRANSACTION_TYPE)),
    /** Redux state property, is true when loading player info  */
    isLoading: PropTypes.bool,
    /** Redux action to load player info */
    getPlayerInfo: PropTypes.func,
    /** Redux state property, player info */
    playerInfo: playerInfoType,
    /** Redux action to deposit to player */
    depositToPlayer: PropTypes.func,
    /** Redux action to withdraw from player */
    withdrawPlayer: PropTypes.func,
}

const mapDispatchToProps = dispatch => (
    {
        getPlayerInfo: (data, onSuccess) => {
            dispatch(getPlayerInfo(data, onSuccess))
        },

        depositToPlayer: (data, onSuccess) => {
            dispatch(depositToPlayer(data, onSuccess))
        },

        withdrawPlayer: (data, onSuccess) => {
            dispatch(withdrawPlayer(data, onSuccess))
        },
    }
)

const mapStateToProps = state => {
    return {
        isLoading: state.players.isLoading,
        playerInfo: state.players.playerInfo
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    TransferPlayerPopupComponent
);