import React, {Fragment} from 'react';
import {VOUCHER_GENERAL_DETAILS, VOUCHER_INFO_DETAILS} from "constants/voucher.constants";
import {binaryToFlags} from "utils/common";
import {useTranslation} from "react-i18next";
import useFormat from "hooks/useFormat";
import BarCode from "components/common/tickets/barCode";
import Logo from "assets/images/logo-blue.svg"
import {getUser} from "utils/auth";
import {connect} from "react-redux";
import moment from "moment";
import {DATE_TIME_FORMAT} from "constants/common.constants";
import TicketCopyImg from "assets/images/ticket_copy.png";

const VoucherTicket = ({ userInfo, voucher, reprint }) => {
    const { t } = useTranslation();

    const { formatAmount } = useFormat();

    const companyLongId = getUser()?.companyLongId;
    const projectLongId = getUser()?.projectLongId;

    const { voucherTicket } = userInfo;

    const generalDetails = binaryToFlags(Object.values(VOUCHER_GENERAL_DETAILS), voucherTicket.generalDetails);
    const infoDetails = binaryToFlags(Object.values(VOUCHER_INFO_DETAILS), voucherTicket.infoDetails);

    const voucherCode = "VC" + voucher.code;

    return (
        <div id={voucher.id} key={voucher.id} className="rt--print-wrapper">
            <div className={"rt--print" + (reprint ? " rt--print-copy" : "")}>
                {reprint && (
                    <>
                        <span className="rt--print-copy-left">Copy</span>
                        <span className="rt--print-copy-right">Copy</span>
                    </>
                )}

                <div className="rt--print-header rt--justify-center rt--flex rt--mb-16">
                {
                        voucherTicket.ticketLogoId ?
                            <img src={`${import.meta.env.SYSTEM_CDN_URL}/companies/${companyLongId}/projects/${projectLongId}/images/${voucherTicket.ticketLogoId}_ticket_logo.png`} alt="logo" /> :
                            <img src={Logo} alt="logo" />
                    }
                </div>

                <div className="rt--print-info-dashed rt--font-bigest rt--font-medium rt--justify-center rt--flex rt--mb-16 rt--pb-16">
                    {t('common.voucher')}
                </div>
                {
                    (
                        generalDetails.includes(VOUCHER_GENERAL_DETAILS.BETSHOP_NAME) ||
                        generalDetails.includes(VOUCHER_GENERAL_DETAILS.CASHIER_USERNAME) ||
                        generalDetails.includes(VOUCHER_GENERAL_DETAILS.TERMINAL_NAME)
                    ) && (
                        <div className="rt--print-info rt--print-info-dashed rt--pb-16 rt--mb-16">
                            {
                                generalDetails.includes(VOUCHER_GENERAL_DETAILS.BETSHOP_NAME) && (
                                    <div className="rt--print-info-row rt--flex rt--align-center rt--justify-between rt--mb-4">
                                        <span
                                            className='rt--font-normal rt--font-regular'>{t('common.betshopName')}</span>
                                        <span
                                            className='rt--font-normal rt--font-regular'>{userInfo.betShopName}</span>
                                    </div>
                                )
                            }
                            {
                                generalDetails.includes(VOUCHER_GENERAL_DETAILS.CASHIER_USERNAME) && (
                                    <div className="rt--print-info-row rt--flex rt--align-center rt--justify-between rt--mb-4">
                                        <span
                                            className='rt--font-normal rt--font-regular'>{t('common.cashierUsername')}</span>
                                        <span
                                            className='rt--font-normal rt--font-regular'>{userInfo.cashier.userName}</span>
                                    </div>
                                )
                            }
                            {/*{*/}
                            {/*    generalDetails.includes(VOUCHER_GENERAL_DETAILS.TERMINAL_NAME) && (*/}
                            {/*        <div className="rt--print-info-row rt--flex rt--align-center rt--justify-between rt--mb-4">*/}
                            {/*            <span*/}
                            {/*                className='rt--font-normal rt--font-regular'>{t('common.terminalName')}</span>*/}
                            {/*            <span*/}
                            {/*                className='rt--font-normal rt--font-regular'>{VOUCHER_PREVIEW_VALUES.TERMINAL_NAME}</span>*/}
                            {/*        </div>*/}
                            {/*    )*/}
                            {/*}*/}
                        </div>
                    )
                }

                {
                    (
                        generalDetails.includes(VOUCHER_GENERAL_DETAILS.VOUCHER_ID) ||
                        infoDetails.includes(VOUCHER_INFO_DETAILS.VOUCHER_CODE) ||
                        infoDetails.includes(VOUCHER_INFO_DETAILS.PAYOUT_DATE) ||
                        infoDetails.includes(VOUCHER_INFO_DETAILS.EXPIRATION_DATE)
                    ) && (
                        <div className="rt--print-info rt--print-info-dashed rt--pb-16 rt--mb-16">
                            <div className="rt--print-info-bet">
                                {
                                    generalDetails.includes(VOUCHER_GENERAL_DETAILS.VOUCHER_ID) && (
                                        <div
                                            className="rt--print-info-row rt--flex rt--align-center rt--justify-between rt--mb-4">
                                            <span
                                                className='rt--font-normal rt--font-regular'>{t('common.voucherId')}</span>
                                            <span
                                                className='rt--font-normal rt--font-regular'>{voucher.id}</span>
                                        </div>
                                    )
                                }
                                {
                                    infoDetails.includes(VOUCHER_INFO_DETAILS.VOUCHER_CODE) && (
                                        <div
                                            className="rt--print-info-row rt--flex rt--align-center rt--justify-between rt--mb-4">
                                            <span
                                                className='rt--font-normal rt--font-regular'>{t('common.voucherCode')}</span>
                                            <span
                                                className='rt--font-normal rt--font-regular'>{voucherCode}</span>
                                        </div>
                                    )
                                }
                                {
                                    infoDetails.includes(VOUCHER_INFO_DETAILS.PAYOUT_DATE) && (
                                        <div
                                            className="rt--print-info-row rt--flex rt--align-center rt--justify-between rt--mb-4">
                                            <span
                                                className='rt--font-normal rt--font-regular'>{t('common.issueDate')}</span>
                                            <span
                                                className='rt--font-normal rt--font-regular'>{moment.utc(voucher.createdAt).local().format(DATE_TIME_FORMAT)}</span>
                                        </div>
                                    )
                                }

                                {
                                    (
                                        infoDetails.includes(VOUCHER_INFO_DETAILS.EXPIRATION_DATE) &&
                                        voucher.expirationDate
                                    ) && (
                                        <div
                                            className="rt--print-info-row rt--flex rt--align-center rt--justify-between rt--mb-4">
                                            <span
                                                className='rt--font-normal rt--font-regular'>{t('common.expirationDate')}</span>
                                            <span
                                                className='rt--font-normal rt--font-regular'>{moment.utc(voucher.expirationDate).local().format(DATE_TIME_FORMAT)}</span>
                                        </div>
                                    )
                                }

                            </div>
                        </div>
                    )
                }

                {infoDetails.includes(VOUCHER_INFO_DETAILS.VOUCHER_AMOUNT) && (
                    <div className="rt--print-info rt--print-info-dashed rt--pb-16 rt--mb-16">
                        <div className="rt--print-info-bet">
                            <div className="rt--print-info-row rt--flex rt--align-center rt--justify-between rt--mb-4">
                                <span
                                    className='rt--font-biger rt--font-regular rt--font-bold'>{t('common.voucherAmount')}</span>
                                <span
                                    className='rt--font-biger rt--font-regular rt--font-bold'>{`${formatAmount(voucher.amount, voucher.currencyCode)} ${voucher.currencyCode}`}</span>
                            </div>
                        </div>
                    </div>
                )}

                <div className="rt--print-footer">
                    <div className="rt--print-footer-inner">
                        <div className="rt--print-footer-barcode rt--print-footer-barcode-voucher rt--mb-16">
                            {
                                voucherTicket.enableBarcodePrinting && (
                                    <>
                                        <BarCode value={voucherCode} />
                                    </>
                                )
                            }
                        </div>
                        {
                            voucherTicket.customText && (
                                <span className="rt--font-normal rt--font-regular rt--print-footer-custom-text">{voucherTicket.customText}</span>
                            )
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = state => {
    return {
        userInfo: state.profile.userInfo,
    }
}

export default connect(mapStateToProps)(VoucherTicket);
