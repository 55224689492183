import React, {useMemo} from 'react';

import {useTranslation} from "react-i18next";
import {Col, Row, Tabs as AntTabs} from "antd";
import RetailLineChart from "components/manager/dashboard/components/retailLineChart";
import {RETAIL_PERFORMANCE_STATISTICS_LINE_CHARTS_DATA, TOTALS_TYPES} from "components/manager/dashboard/constants";
import TotalsCard from "components/manager/dashboard/components/totalsCard";

const StatisticsTab = ({ filters, updateLastUpdatedDate }) => {
    const { t } = useTranslation();

    const lineChartItems = useMemo(() => (
        RETAIL_PERFORMANCE_STATISTICS_LINE_CHARTS_DATA.map(data => ({
            key: data.id,
            label: t(data.title),
            children: (
                <RetailLineChart
                    key={data.id}
                    title={t(data.title)}
                    dataType={data.dataType}
                    playerLineColor={data.playerLineColor}
                    betshopLineColor={data.betshopLineColor}
                    disableLegend={data.disableLegend}
                    filters={filters}
                    updateLastUpdatedDate={updateLastUpdatedDate}
                    calcTotalAmount={data.calcTotalAmount}
                />
            )
        }))
    ), [filters, t, updateLastUpdatedDate]);

    return (
        <div className="rt--dashboard-statistics-retail">
            <Row gutter={16} className="rt--mb-24">
                <Col span={6}>
                    <TotalsCard
                        type={TOTALS_TYPES.BET}
                        color="#838FFF"
                        filters={filters}
                        title={t("common.betAmount")}
                        subtitle={t("common.betCount")}
                        updateLastUpdatedDate={updateLastUpdatedDate}
                    />
                </Col>
                <Col span={6}>
                    <TotalsCard
                        type={TOTALS_TYPES.PENDING}
                        color="#FD026F"
                        filters={filters}
                        title={t("common.pendingBetAmount")}
                        subtitle={t("common.pendingBetCount")}
                        updateLastUpdatedDate={updateLastUpdatedDate}
                    />
                </Col>
                <Col span={6}>
                    <TotalsCard
                        type={TOTALS_TYPES.WIN}
                        color="#19FEA9"
                        filters={filters}
                        title={t("common.winAmount")}
                        subtitle={t("common.winCount")}
                        updateLastUpdatedDate={updateLastUpdatedDate}
                    />
                </Col>
                <Col span={6}>
                    <TotalsCard
                        type={TOTALS_TYPES.PAYOUT}
                        color="#FFBE0C"
                        filters={filters}
                        title={t("common.payoutAmount")}
                        subtitle={t("common.payoutCount")}
                        updateLastUpdatedDate={updateLastUpdatedDate}
                    />
                </Col>
            </Row>

            <div className="rt--chart-section">
                <AntTabs
                    animated={false}
                    destroyInactiveTabPane={true}
                    className='rt--tabs rt--chart-tab'
                    items={lineChartItems}
                >
                    {lineChartItems.map(item => (
                        <AntTabs.TabPane tab={item.label} key={item.key}>
                            {item.children}
                        </AntTabs.TabPane>
                    ))}
                </AntTabs>
            </div>
        </div>
    );
};

export default StatisticsTab;
