import React from "react";

import LeaderboardDesktopView from "./desktopView";

import { LEADERBOARD_TYPE } from "./constants";

const Leaderboard = ({
    // common
    type = LEADERBOARD_TYPE.FINANCIAL,

    // data
    leaders = [],
    isLoading = false,
    currencyCode,

    // header
    title,
    showTitle,
    extraContent,
    extraContentPosition,
}) => {
    return (
        <div
            className="rt--pb-24"
        >
            <LeaderboardDesktopView
                leaders={leaders}
                isLoading={isLoading}
                type={type}
                currencyCode={currencyCode}
            />
        </div>
    )
};

export default Leaderboard;
