import UsersFilters from "components/manager/dashboard/tabs/usersTab/filters";
import StatisticsFilters from "components/manager/dashboard/tabs/statisticsTab/filters";

export const DASHBOARD_TAB_NAMES = {
    USERS: "users",
    STATISTICS: "statistics",
}

export const DASHBOARD_FILTERS = {
    [DASHBOARD_TAB_NAMES.STATISTICS]: StatisticsFilters,
}
