import React from "react";
import PropTypes from 'prop-types';

import Highcharts from 'highcharts'
import PatternFill from 'highcharts/modules/pattern-fill';
import HighchartsReact from 'highcharts-react-official'

import { classNames } from "utils/common";
import Loader from "components/common/loader";

PatternFill(Highcharts);

// To hide the indication line after the mouse leaves the chart area
Highcharts.wrap(
    Highcharts.Pointer.prototype,
    'onContainerMouseLeave',
    function (proceed) {
        proceed.apply(this, Array.prototype.slice.call(arguments, 1));

        if (Boolean(this.chart.customcrosshair)) {
            this.chart.customcrosshair.destroy();
            this.chart.customcrosshair = null;
        }
    }
);

Highcharts.wrap(Highcharts.Tooltip.prototype, 'getPosition', function (proceed, boxWidth, boxHeight, point) {
    const { scaleX, scaleY } = this.pointer.getChartPosition();

    boxWidth = boxWidth * scaleX;
    boxHeight = boxHeight * scaleY;

    const e = proceed.call(this, boxWidth, boxHeight, point);

    e.x = e.x / scaleX + (32 - 32 * scaleX);
    e.y = e.y / scaleY - (16 - 16 * scaleX);

    return e;
});

/** LineChart Component */
const LineChart = ({
    renderHeader,
    hideChart = false,
    configs = {},
    isLoading = false,
    total,
}) => {
    return (
        <div
            className="rt--chart-operational-line rt--pb-16 rt--pl-4 rt--pr-16"
        >
            {
                typeof renderHeader === "function"
                    ? (
                        renderHeader()
                    ) : (
                        <div
                            className={classNames(
                                "rt--flex rt--mb-8",
                                "rt--justify-between",
                            )}
                        >
                            {Boolean(total) && (
                                <span
                                    className="rt--title rt--ml-10 rt--mb-10"
                                >
                                    {total}
                                </span>
                            )}
                        </div>
                    )
            }

            <div className="rt--chart-section-block">
                {
                    isLoading ? (
                        <Loader style={{ height: '100%' }} />
                    ) : (!hideChart) && (
                        <HighchartsReact isLoading={isLoading} highcharts={Highcharts} options={configs} />
                    )
                }
            </div>
        </div>
    )
};

/** LineChart propTypes*/
LineChart.propTypes = {
    hideChart: PropTypes.bool,
    isLoading: PropTypes.bool,
    title: PropTypes.string,
    renderHeader: PropTypes.func,
    total: PropTypes.string,
    /**
     * All configs for line chart (can be constructed via <constructLineChartConfigs> function)
     */
    configs: PropTypes.object,
};

export default LineChart;
