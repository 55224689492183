import React, { useMemo } from 'react';

import Table from "components/common/table";

import { getTableColumns } from './columns';

import useFormat from 'hooks/useFormat';
import {useTranslation} from "react-i18next";
import noDataImg from "assets/images/noContent.png";

const LeaderboardDesktopView = ({
    leaders,
    isLoading,
    type,
    currencyCode
}) => {
    const { t } = useTranslation();
    const { formatAmount } = useFormat();

    const columns = useMemo(() => (
        getTableColumns({ t, type, currencyCode, formatAmount })
    ), [type, currencyCode, formatAmount, t])

    return (
        <Table
            name="leaderboard"
            isLoading={isLoading}
            data={leaders}
            columns={columns}
            emptyImg={noDataImg}
            emptyText={t("common.noDataFound")}
        />
    );
}
// <Table
//     name={name || "totals"}
//     isLoading={isLoading}
//     loadFn={loadFn}
//     data={totalsArray}
//     columns={columnsWithRender}
//     emptyImg={noDataImg}
//     emptyText={t("common.noData")}
// />

export default LeaderboardDesktopView;
