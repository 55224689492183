import {useDashboardContext} from "components/manager/dashboard/contexts/dashboardContext";
import {useTranslation} from "react-i18next";
import React, {useMemo} from "react";
import {DATA_TYPES, LEADERBOARD_TYPES, TIME_PERIOD} from "components/manager/dashboard/constants";
import {Select} from "antd";
import {useSelector} from "react-redux";
import Label from "components/common/label";


const LeaderboardFilters = ({ filters, onChange, currencyOptions, dataTypeOptions }) => {
    const betshopNames = useSelector(state => state.managerBetshops.availableBetshops);

    const { state: { leaderboardTab } } = useDashboardContext();

    const { t } = useTranslation();

    const betShopOptions = useMemo(() => ([
        {
            label: t('common.allBetshops'),
            value: ''
        },
        ...betshopNames.map(betshop => ({
            label: betshop.name,
            value: betshop.id
        }))
    ]), [betshopNames, t]);

    const filteredDataTypeOptions = useMemo(() => {
        return dataTypeOptions.filter(option => option.value !== DATA_TYPES.COMMISSIONS);
    }, [dataTypeOptions]);

    return (
        <div className="rt--leaderboard-filters rt--mb-24">
            <Label label={t('common.type')}>
                <Select
                    value={filters.dataType}
                    onChange={value => onChange({ dataType: value })}
                    options={filteredDataTypeOptions}
                    className="rt--select"
                />
            </Label>

            {leaderboardTab === LEADERBOARD_TYPES.CASHIER && (
                <Label label={t('common.betshops')}>
                    <Select
                        className="rt--select"
                        value={filters.betShopNameOrId}
                        onChange={value => onChange({ betShopNameOrId: value })}
                        placeholder={t('common.allBetshops')}
                        options={betShopOptions}
                        search
                    />
                </Label>
            )}

            <Label label={t('common.dateRange')}>
                <Select
                    className="rt--select"
                    value={filters.timePeriod}
                    onChange={value => onChange({ timePeriod: value })}
                    options={
                        [
                            { value: TIME_PERIOD.TODAY, label: t("common.today") },
                            { value: TIME_PERIOD.YESTERDAY, label: t("common.yesterday") },
                            { value: TIME_PERIOD.THIS_WEEK, label: t("common.thisWeek") },
                            { value: TIME_PERIOD.LAST_WEEK, label: t("common.lastWeek") },
                            { value: TIME_PERIOD.THIS_MONTH, label: t("common.thisMonth") },
                            { value: TIME_PERIOD.LAST_MONTH, label: t("common.lastMonth") },
                            { value: TIME_PERIOD.LAST_QUARTER, label: t("common.lastQuarter") },
                            { value: TIME_PERIOD.THIS_YEAR, label: t("common.ytd") },
                            { value: TIME_PERIOD.LAST_YEAR, label: t("common.lastYear") },
                        ]
                    }
                />
            </Label>

            <Label label={t('common.currency')}>
                <Select
                    className="rt--select"
                    value={filters.currencyCode}
                    onChange={value => onChange({ currencyCode: value })}
                    options={[
                        ...currencyOptions
                    ]}
                />
            </Label>
        </div>
    )
};

export default LeaderboardFilters;
