import React, {useEffect, useMemo, useState} from 'react';
import {getUser} from "utils/auth";
import {USER_ROLE, USER_TYPE} from "constants/user.constants";
import {useTranslation} from "react-i18next";
import {VOUCHER_STATE} from "constants/voucher.constants";
import {Input} from "antd";
import Filters from "components/common/filters";
import {DATE_PICKER_RANGES} from "constants/common.constants";
import {
    getVoucherHistory,
    getVoucherHistoryTotals,
    setVoucherHistoryFilters, setVoucherHistorySorting
} from "store/actions/dashboard/voucherHisrory.action";
import {getManagerAvailableCashiers} from "store/actions/dashboard/cashiers.action";
import {getAvailableCashiers, getAvailableTerminals} from "store/actions/dashboard/autosuggestion.action";
import {connect} from "react-redux";
import {getManagerAvailableBetshops} from "store/actions/dashboard/managerBetshops.action";
import {getUserAvailableCurrencies} from "store/actions/dashboard/userInfo.action";
import ApiUrls from "constants/api.constants";
import Export from "components/common/export";
import getColumns from "components/common/voucherHistory/helpers/getColumns";
import useFormat from "hooks/useFormat";

let timer;

const VoucherHistoryFilters = ({
    filters,
    sorting,
    availableCashiers,
    availableTerminals,
    availableBetshops,
    currencies,
    getVoucherHistory,
    getVoucherHistoryTotals,
    setVoucherHistoryFilters,
    getAvailableTerminals,
    getAvailableCashiers,
    getManagerAvailableBetshops,
    getUserAvailableCurrencies
}) => {
    const {t} = useTranslation();
    const { formatAmount } = useFormat();

    const [opened, setOpened] = useState(false);
    const [searchValue, setSearchValue] = useState(filters.id);
    const [creatorType, setCreatorType] = useState("");

    const isCashier = getUser()?.role === USER_ROLE.CASHIER;

    const columns = useMemo(() => getColumns({ t, formatAmount, isCashier }), [])

    useEffect(() => {
        getAvailableTerminals();
        getAvailableCashiers();
        !isCashier && getManagerAvailableBetshops();
        !isCashier && getUserAvailableCurrencies();
    }, [isCashier]);

    const statusOptions = useMemo(() => {
        return [
            {
                title: t('common.all'),
                value: ""
            },
            {
                title: t('common.active'),
                value: VOUCHER_STATE.ACTIVE
            },
            {
                title: t('common.expired'),
                value: VOUCHER_STATE.EXPIRED
            },
            {
                title: t('common.paidOut'),
                value: VOUCHER_STATE.PAID_OUT
            },
            {
                title: t('common.used'),
                value: VOUCHER_STATE.USED
            },
        ]
    }, [t]);

    /** Function, handler for betslipId search input
     * @function
     * @param {string} value - the field value
     * @memberOf BetHistoryFilters
     */
    const handleInputChange = value => {
        setSearchValue(value);
        clearTimeout(timer);
        timer = setTimeout(() => {
            if (!value || value.length > 2) {
                setVoucherHistoryFilters({
                    id: value && value.length > 2 ? value : ""
                })
                setTimeout(() => {
                    getVoucherHistory();
                    getVoucherHistoryTotals();
                }, 0)
            }
        }, 300)
    }

    return (
        <div className='rt--filters-header rt--pb-16 rt--mb-30'>
            {isCashier ? (
                <div className='rt--flex rt--justify-between rt--align-center'>
                    <div className='rt--filters-header-search'>
                        <Input
                            placeholder={t("common.searchByVoucherId")}
                            onChange={e => handleInputChange(e.target.value)}
                            value={searchValue}
                            allowClear={true}
                        />
                        <i className='icon-search rt--font-bigest'></i>
                    </div>

                    <div className="rt--flex rt--align-center">
                        <Export
                            filters={{
                                ...filters,
                                ...sorting
                            }}
                            title={t("common.voucherHistory")}
                            columns={columns}
                            url={ApiUrls.EXPORT_VOUCHERS}
                            tableName={t("common.voucherHistory")}
                        />

                        <button
                            className='rt--button rt--button-secondary rt--ml-16'
                            onClick={() => setOpened(!opened)}
                        >
                            <span className='rt--flex rt--justify-center rt--align-center'>
                                <i className='icon-filter rt--font-bigest'></i>
                                <span
                                    className='rt--title rt--font-medium rt--font-normal'>{t("common.filters")}</span>
                            </span>
                        </button>
                    </div>
                </div>
            ) : (
                <div className='rt--flex rt--justify-between rt--align-center rt--mt-16'>
                    <div className='rt--flex rt--align-center rt--filters-header-title'>
                        <span className='rt--title rt--font-big rt--font-regular'>
                            {t("common.voucherHistory")}
                        </span>
                    </div>

                    <div className='rt--flex'>
                        <div className='rt--filters-header-search'>
                            <Input
                                placeholder={t("common.searchByVoucherId")}
                                onChange={e => handleInputChange(e.target.value)}
                                value={searchValue}
                                allowClear={true}
                            />
                            <i className='icon-search rt--font-bigest'></i>
                        </div>

                        <div className="rt--flex rt--align-center">
                            <Export
                                filters={{
                                    ...filters,
                                    ...sorting
                                }}
                                title={t("common.voucherHistory")}
                                columns={columns}
                                url={ApiUrls.EXPORT_VOUCHERS}
                                tableName={t("common.voucherHistory")}
                            />

                            <button
                                className='rt--button rt--button-secondary rt--ml-16'
                                onClick={() => setOpened(!opened)}
                            >
                                <span className='rt--flex rt--justify-center rt--align-center'>
                                    <i className='icon-filter rt--font-bigest'></i>
                                    <span
                                        className='rt--title rt--font-medium rt--font-normal'>{t("common.filters")}</span>
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
            )}
            <Filters
                loadFn={() => {
                    getVoucherHistory();
                    getVoucherHistoryTotals();
                }}
                setFiltersFn={f => setVoucherHistoryFilters({...f, creatorType})}
                filters={filters}
                useDateWithTime={true}
                controls={[
                    {
                        title: t('common.timePeriod'),
                        name: "period",
                        type: "SELECT",
                        small: true,
                        items: [
                            {value: DATE_PICKER_RANGES.TODAY, title: t('common.today')},
                            {value: DATE_PICKER_RANGES.YESTERDAY, title: t('common.yesterday')},
                            {value: DATE_PICKER_RANGES.THIS_MONTH, title: t('common.thisMonth')},
                            {value: DATE_PICKER_RANGES.LAST_MONTH, title: t('common.lastMonth')},
                            {value: DATE_PICKER_RANGES.LAST_3_MONTH, title: t('common.3Months')},
                            {value: DATE_PICKER_RANGES.YTD, title: t('common.ytd')}
                        ]
                    },
                    {
                        title: t('common.dateRange'),
                        name: "date",
                        type: "RANGEPICKER",
                        enabledMountsCount: 12
                    },
                    {
                        title: t('common.status'),
                        name: "status",
                        type: "SELECT",
                        small: true,
                        items: statusOptions
                    },
                    {
                        title: t('common.createdBy'),
                        name: "createdBy",
                        type: "SELECT",
                        onChange: (_, __, option) => {
                            setCreatorType(option.type);
                        },
                        items: [
                            { value: "", title: t('common.all') },
                            ...availableCashiers.map(b => ({
                                value: b.id, title: b.name, type: USER_TYPE.CASHIER
                            })),
                            ...availableTerminals.map(b => ({
                                value: b.id, title: b.name, type: USER_TYPE.TERMINAL
                            }))
                        ]
                    },
                    !isCashier && {
                        title: t('common.betshop'),
                        name: "betShopNameOrId",
                        type: "SELECT",
                        items: [
                            { value: "", title: t('common.all') },
                            ...availableBetshops.map(b => ({
                                value: b.id, title: b.name
                            }))
                        ],
                        small: true
                    },
                    !isCashier && {
                        title: t('common.currency'),
                        name: "currencyCode",
                        type: "SELECT",
                        items: [
                            { value: "", title: t("common.all") },
                            ...currencies.map(b => ({ value: b, title: b })),
                        ],
                        small: true
                    }
                ]}
                visible={opened}
            />
        </div>
    );
};

const mapStateToProps = (state) => ({
    filters: state.voucherHistory.filters,
    sorting: state.voucherHistory.sorting,
    availableCashiers: state.autosuggestion.cashiers,
    availableTerminals: state.autosuggestion.terminals,
    availableBetshops: state.managerBetshops.availableBetshops,
    currencies: state.profile.currencies
});

const mapDispatchToProps = (dispatch) => ({
    getVoucherHistory: () => dispatch(getVoucherHistory()),
    getVoucherHistoryTotals: () => dispatch(getVoucherHistoryTotals()),
    setVoucherHistoryFilters: (filters) => dispatch(setVoucherHistoryFilters(filters)),
    setVoucherHistorySorting: (filters) => dispatch(setVoucherHistorySorting(filters)),
    getManagerAvailableCashiers: id => dispatch(getManagerAvailableCashiers(id)),
    getAvailableTerminals: () => dispatch(getAvailableTerminals()),
    getAvailableCashiers: () => dispatch(getAvailableCashiers()),
    getManagerAvailableBetshops: () => dispatch(getManagerAvailableBetshops()),
    getUserAvailableCurrencies: () => dispatch(getUserAvailableCurrencies()),
});


export default connect(mapStateToProps, mapDispatchToProps)(VoucherHistoryFilters);
