import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import { getDomain, binaryToFlags } from 'utils/common'
import { getUser } from 'utils/auth'
import LanguageUtils from 'utils/languages';

import { PROJECT_PROVIDER_TYPE } from 'constants/common.constants'
import { TICKET_GENERAL_DETAILS } from 'constants/ticket.constants';

import userInfoType from 'types/userInfo.type';
import LaunchError from "components/cashier/frame/launchError";

/** Sport Iframe Component */
const SportComponent = ({
    providers,
    userInfo,
}) => {

    const companyLongId = getUser()?.companyLongId;
    const projectLongId = getUser()?.projectLongId;

    /** Sport provider data */
    const data = providers[PROJECT_PROVIDER_TYPE.SPORTBOOK];
    const { hasError } = data;

    /** Ticket details */
    const ticketDetails = userInfo.payoutTicket;

    /** Ticket details general sections */
    const generalDetails = binaryToFlags(Object.values(TICKET_GENERAL_DETAILS), ticketDetails.generalDetails);

    const sportRef = useRef(null);
    const betShopApp = useRef(null);

    /** Start sport integration */
    useEffect(() => {
        if(data?.token){
            startSport();
        }

        return () => {
            window.Bootstrapper && window.Bootstrapper.destroy && window.Bootstrapper.destroy();
        }
    }, [data?.token])

    /** Destroy on unmounth */
    useEffect(() => {
        return () => {
            if (betShopApp && betShopApp.current) {
                betShopApp.current.destroy();
            }
        }
    }, [])

    /** Function to start sport integration
       * @function
       * @memberOf SportComponent
    */
    const startSport = () => {
        const domain = getDomain()
        if (!document.getElementById('sportsbook-js')) {
            const script = document.createElement("script");
            script.src = import.meta.env.SYSTEM_APP_MODE === "production" ? `https://sport.${domain}/js/partner/bootstrapper.min.js` : `https://sport-${domain}/js/partner/bootstrapper.min.js`;

            script.id = 'sportsbook-js';
            script.onload = function () {
                launchApp();
            }
            document.body.appendChild(script);
        } else {
            launchApp();
        }
    }

    /** Function to launch sport integration
       * @function
       * @memberOf SportComponent
    */
    const launchApp = () => {
        document.getElementById("rt--sport-target").innerHTML = '';
        const language = LanguageUtils.getSelectedLanguage();
        const domain = getDomain();

        const token = data?.token ?? null;
        const sportPartner = data?.sportPartnerId ?? null;
        const params = {
            token: token || '-',
            language: language,
            server: import.meta.env.SYSTEM_APP_MODE === "production" ? `https://sport.${domain}/` : `https://sport-${domain}/`,
            containerId: 'rt--sport-target',
            mirroringScriptUrl: `${import.meta.env.SYSTEM_CDN_URL}${import.meta.env.SYSTEM_INTEGRATION_SCRIPT}?fake=${Math.random()}`,
            sportPartner: sportPartner,
            logoPath: ticketDetails.ticketLogoId ? `${import.meta.env.SYSTEM_CDN_URL}/companies/${companyLongId}/projects/${projectLongId}/images/${ticketDetails.ticketLogoId}_ticket_logo.png` : "",
            betShopText: ticketDetails.customText || "",
            userType: "WebCashier",
        };


        if(generalDetails.includes(TICKET_GENERAL_DETAILS.BETSHOP_NAME)){
            params.betShopName = userInfo.betShopName;
        }

        if(generalDetails.includes(TICKET_GENERAL_DETAILS.BETSHOP_ADDRESS)){
            params.betShopAddress = userInfo.betShopAddress;
        }

        if(generalDetails.includes(TICKET_GENERAL_DETAILS.CASHIER_USERNAME)){
            params.betShopCashier = userInfo?.cashier?.userName ?? "";
        }

        if (window.Bootstrapper) {
            console.log("launching sport with params", params)
            window.Bootstrapper.bootIframe(params, {
                name: "BetShop",
            }).then(app => betShopApp.current = app);
        }

    }

    if (hasError) {
        return <LaunchError />
    }

    return (
        <div id="rt--sport-target" className='rt--provider-sport' ref={sportRef}></div>
    )
}

/** SportComponent propTypes
    * PropTypes
*/
SportComponent.propTypes = {
    /** Redux state property, providers data */
    providers: PropTypes.object,
    /** Redux state property, current user info */
    userInfo: userInfoType
}

const mapStateToProps = state => {
    return {
        providers: state.providers.providers,
        userInfo: state.profile.userInfo
    }
}

export default connect(mapStateToProps, null)(SportComponent)
