import React, {useEffect} from 'react';
import PropTypes from "prop-types";

import { useTranslation } from "react-i18next";

import CheckboxesDropdown from "components/common/checkboxesDropdown";

import { exportData } from "./heplers/exportData";
import { mapColumnsForExport } from "./heplers/mapColumnsForExport";

import { toUpperCaseFirstLetter } from "utils/common";

import { EXPORT_TYPE } from "constants/common.constants";
import {message, Spin} from "antd";

const Export = ({
    columns,
    tableName,
    url,
    filters,
    isPostRequest
}) => {
    const { t } = useTranslation();

    const filteredColumns = columns.filter(column => !!column);

    /** Function , do export
     * @function
     * @param {array} value - selected columns
     * @param {string} exportType - pdf/csv
     * @memberOf ExportButton
     */
    const doExport = (value, exportType) => {
        const exportColumns = value.map(c => ({
            key: toUpperCaseFirstLetter(c),
            title: t(filteredColumns.find(col => [col.key, col.exportKey].includes(c))?.title ?? c)
        }));

        const messageId = new Date().getTime().toString();

        message.open({
            key: 'export' + messageId,
            className: 'rt--export-message',
            content: (
                <div className="rt--export-message-content">
                    <Spin size="small" />{t("common.downloadingFile")}
                </div>
            ),
            duration: 0
        })

        exportData(url, exportType, exportColumns, tableName, filters, isPostRequest)
            .then(() => {
                message.open({
                    key: 'export' + messageId,
                    className: 'rt--export-message',
                    content: t("common.downloadComplete"),
                    duration: 3,
                    type: "success"
                })
            })
            .catch(() => {
                message.open({
                    key: 'export' + messageId,
                    className: 'rt--export-message',
                    content: t("common.downloadFailed"),
                    duration: 3,
                    type: "error"
                })
            })
    }

    return (
        <CheckboxesDropdown
            columns={mapColumnsForExport(filteredColumns)}
            buttons={[
                { text: t('common.csv'), onClick: value => doExport(value, EXPORT_TYPE.CSV) }
            ]}
            buttonText={t('common.export')}
            buttonClassName="rt--ml-16 rt--filters-header-export-button"
            infoText={t('common.exportLimitationInfo').replace('%X%', '200,000')}
            headerDescription={t('common.exportLimitationDescription').replace('%X%', '200,000')}
            allowEmpty={false}
            selectAllByDefault={true}
        />
    )
};

Export.propTypes = {
    /** Array od columns of table which can be exported */
    columns: PropTypes.arrayOf(PropTypes.shape({
        title: PropTypes.string,
        key: PropTypes.string
    })),
    /** Table Name */
    tableName: PropTypes.string,
    /** Export api url */
    url: PropTypes.string,
    /** Table filters */
    filters: PropTypes.object,
    /** Is the request post */
    isPostRequest: PropTypes.bool
}

export default Export;
