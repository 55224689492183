import React from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';

import useDate from 'hooks/useDate';

const LastUpdated = ({ lastUpdatedDate }) => {
    const { t } = useTranslation();

    const { dateService } = useDate();

    return (
        <div className='rt--flex rt--align-center rt--chart-last-update'>
            <i className='icon-clock rt--font-bigest rt--mr-6'/>
            <span className="rt--mr-16">
                {`${t("common.lastUpdatedAt")} ${!lastUpdatedDate ? "" : dateService.format(lastUpdatedDate, true, false)}`}
            </span>
        </div>
    )
}

LastUpdated.propTypes = {
    lastUpdatedDate: PropTypes.string
}

export default LastUpdated;
