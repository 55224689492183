import {useDashboardContext} from "components/manager/dashboard/contexts/dashboardContext";
import {useTranslation} from "react-i18next";
import {useMemo} from "react";
import {
    DATA_TYPES, LEADERBOARD_TYPES, RETAIL_LEADERBOARD_DATA, RETAIL_PIE_CHARTS_DATA
} from "components/manager/dashboard/constants";
// import UsersLeaderboardFinancial from "components/manager/dashboard/tabs/usersTab/components/usersLeaderboardFinancial";
import UsersTabDesktop from "components/manager/dashboard/tabs/usersTab/desktop";
import getDataTypeOptions from "components/manager/dashboard/helpers/getDataTypeOptions";
import UsersLeaderboardFinancial from "components/manager/dashboard/tabs/usersTab/components/usersLeaderboardFinancial";


const UsersTab = ({ filters, currencyOptions, onFiltersChange, updateLastUpdatedDate }) => {
    const { state: { leaderboardTab }, setLeaderboardTab } = useDashboardContext();

    const { t } = useTranslation();

    const leaderboardFilters = useMemo(() => ({
        ...filters.global,
        ...filters.leaderboard,
    }), [filters]);

    const pieChartFilters = useMemo(() => ({
        ...filters.global
    }), [filters.global]);

    const dataTypeOptions = useMemo(() => (
        getDataTypeOptions(leaderboardTab, false, true)
    ), [leaderboardTab])

    const items = useMemo(() => {
        let leaderboards = RETAIL_LEADERBOARD_DATA;

        if (leaderboardFilters.betShopNameOrId) {
            leaderboards = leaderboards.filter(leaderboard =>
                ![LEADERBOARD_TYPES.BETSHOP].includes(leaderboard.id)
            );
        }

        return leaderboards.map(leaderboard => ({
            key: leaderboard.id,
            label: t(leaderboard.label),
            children: (
                <UsersLeaderboardFinancial
                    endpoint={leaderboard.endpoint}
                    filters={leaderboardFilters}
                    updateLastUpdatedDate={updateLastUpdatedDate}
                />
            )
        }))
    }, [leaderboardFilters, t, updateLastUpdatedDate]);

    const pieChartItems = useMemo(() => {
        return RETAIL_PIE_CHARTS_DATA;
    }, [])

    const handleFiltersChange = (value) => {
        if (
            value.betShopNameOrId
        ) {
            setLeaderboardTab(LEADERBOARD_TYPES.CASHIER);
        }

        onFiltersChange('leaderboard', value);
    }

    const handleTabChange = (key) => {
        setLeaderboardTab(key);

        // const resetFilters = {};
        //
        // const currentOptions = getDataTypeOptions(key, false, true);
        //
        // if (!currentOptions.some(option => option.value === leaderboardFilters.dataType)) {
        //     resetFilters.dataType = DATA_TYPES.GGR;
        // }
        //
        // if (key !== LEADERBOARD_TYPES.CASHIER) {
        //     resetFilters.betShopNameOrId = "";
        // }
        //
        // if (Object.keys(resetFilters).length) {
        //     handleFiltersChange(resetFilters);
        // }
    }

    return (
        <UsersTabDesktop
            items={items}
            pieChartItems={pieChartItems}
            dataTypeOptions={dataTypeOptions}
            currencyOptions={currencyOptions}
            leaderboardFilters={leaderboardFilters}
            pieChartFilters={pieChartFilters}
            updateLastUpdatedDate={updateLastUpdatedDate}
            onFiltersChange={handleFiltersChange}
            onTabChange={handleTabChange}
        />
    );
};

export default UsersTab;
