import React, {useEffect, useMemo, useState} from 'react';

import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { Input, Select } from "antd";

import Export from "components/common/export";

import ApiUrls from "constants/api.constants";

import { getTerminals, setTerminalsFilters, setTerminalsSorting } from "store/actions/dashboard/terminals.action";

import getColumns from "../helpers/getColumns";
import {TERMINAL_STATE, TERMINAL_STATUS} from "constants/terminal.constants";

import useFormat from "hooks/useFormat";
import Filters from "components/common/filters";
import AutoComplete from "components/common/autoComplete";
import useAutosuggestion from "hooks/useAutosuggestion";
import {AUTOSUGGESTION_TYPE} from "constants/autosuggestion.constants";

let timer = null;

const TerminalsFilters = ({
    filters,
    getTerminals,
    setTerminalsFilters,
    betShopId
}) => {
    const [opened, setOpened] = useState(false);

    const [terminalSuggestions, getTerminalSuggestions] = useAutosuggestion({ type: AUTOSUGGESTION_TYPE.TERMINAL, dependencies: [betShopId], actionParams: { betShopId } })

    const { t } = useTranslation();
    const {formatAmount} = useFormat();

    const isForBM = !!betShopId;

    const mappedFilters = {
        nameOrId: filters.nameOrId,
        status: filters.status,
        state: filters.state
    }

    const columns = useMemo(() => getColumns({ t, formatAmount }).slice(0, -1), [t, formatAmount]);

    const statusOptions = useMemo(() => {
        return [
            {
                title: t('common.all'),
                value: ""
            },
            {
                title: t('common.active'),
                value: TERMINAL_STATUS.ACTIVE
            },
            {
                title: t('common.inactive'),
                value: TERMINAL_STATUS.INACTIVE
            },
            {
                title: t('common.blocked'),
                value: TERMINAL_STATUS.BLOCKED
            },
        ]
    }, [t]);

    const stateOptions = useMemo(() => [
        {
            title: t('common.all'),
            value: ""
        },
        {
            title: t("common.running"),
            value: TERMINAL_STATE.RUNNING
        },
        {
            title: t("common.notRunning"),
            value: TERMINAL_STATE.NOT_RUNNING
        }
    ])

    useEffect(() => {
        getTerminalSuggestions();
    }, []);

    const handleSearchChange = value => {
        setTerminalsFilters({
            nameOrId: value
        })
        setTimeout(() => {
            getTerminals(betShopId)
        }, 0)
    }

    const handleStatusChange = value => {
        setTerminalsFilters({
            status: value
        })
        setTimeout(() => {
            getTerminals(betShopId)
        }, 0)
    }

    const handleStateChange = value => {
        setTerminalsFilters({
            onlineState: value
        });
        setTimeout(() => {
            getTerminals(betShopId)
        }, 0)
    }

    return (
        <div className='rt--filters-header rt--pb-16 rt--mb-30'>
            <div className='rt--flex rt--justify-between rt--align-end'>
                <div className='rt--flex rt--align-center'>
                    <div>
                        <div className='rt--mb-8'>
                            <span
                                className='rt--title rt--font-regular rt--font-normal'>{t("common.terminalNameOrId")}</span>
                        </div>
                        <div className='rt--filters-header-search'>
                            <AutoComplete
                                placeholder={t("common.search")}
                                items={terminalSuggestions}
                                onSelect={handleSearchChange}
                            />
                            <i className='icon-search rt--font-bigest'></i>
                        </div>
                    </div>

                    {isForBM && (
                        <>
                            <div className='rt--ml-16'>
                                <div className='rt--mb-8'>
                                    <span
                                        className='rt--title rt--font-regular rt--font-normal'>{t("common.status")}</span>
                                </div>
                                <div className='rt--filters-header-search'>
                                    <Select
                                        className='rt--select'
                                        defaultValue=""
                                        suffixIcon={<i className="icon-down rt--font-bigest"></i>}
                                        onChange={handleStatusChange}
                                        getPopupContainer={trigger => trigger.parentNode}
                                        showSearch={false}
                                    >
                                        {
                                            statusOptions.map(item => (
                                                <Select.Option key={item.value}
                                                               value={item.value}>{item.title}</Select.Option>
                                            ))
                                        }
                                    </Select>
                                </div>
                            </div>

                            <div className='rt--ml-16'>
                                <div className='rt--mb-8'>
                                    <span
                                        className='rt--title rt--font-regular rt--font-normal'>{t("common.state")}</span>
                                </div>
                                <div className='rt--filters-header-search'>
                                    <Select
                                        className='rt--select'
                                        defaultValue=""
                                        suffixIcon={<i className="icon-down rt--font-bigest"></i>}
                                        onChange={handleStateChange}
                                        getPopupContainer={trigger => trigger.parentNode}
                                        showSearch={false}
                                    >
                                        {
                                            stateOptions.map(item => (
                                                <Select.Option key={item.value}
                                                               value={item.value}>{item.title}</Select.Option>
                                            ))
                                        }
                                    </Select>
                                </div>
                            </div>
                        </>
                    )}
                </div>

                <div className="rt--flex rt--align-center">
                    <Export
                        filters={mappedFilters}
                        title={t("common.terminals")}
                        columns={columns}
                        url={ApiUrls.EXPORT_TERMINALS}
                        tableName={t("common.terminals")}
                    />

                    {!isForBM && (
                        <button
                            className='rt--button rt--button-secondary rt--ml-16'
                            onClick={() => setOpened(!opened)}
                        >
                            <span className='rt--flex rt--justify-center rt--align-center'>
                                <i className='icon-filter rt--font-bigest'></i>
                                <span
                                    className='rt--title rt--font-medium rt--font-normal'>{t("common.filters")}</span>
                            </span>
                        </button>
                    )}
                </div>
            </div>

            {!isForBM && (
                <Filters
                    loadFn={() => {
                        getTerminals();
                    }}
                    setFiltersFn={setTerminalsFilters}
                    filters={filters}
                    controls={[
                        {
                            title: t('common.status'),
                            name: "status",
                            type: "SELECT",
                            small: true,
                            items: statusOptions
                        },
                        {
                            title: t('common.state'),
                            name: "onlineState",
                            type: "SELECT",
                            small: true,
                            items: stateOptions
                        },
                    ]}
                    visible={opened}
                />
            )}
        </div>
    );
};

const mapStateToProps = (state) => ({
    filters: state.terminals.filters,
})

const mapDispatchToProps = (dispatch) => ({
    getTerminals: betShopId => dispatch(getTerminals(betShopId)),
    setTerminalsFilters: (filters) => dispatch(setTerminalsFilters(filters)),
    setTerminalsSorting: (filters) => dispatch(setTerminalsSorting(filters)),
})

export default connect(mapStateToProps, mapDispatchToProps)(TerminalsFilters);
