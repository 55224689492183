import React, { useRef, useEffect } from 'react';

import PropTypes from 'prop-types';
import moment from "moment";
import { useTranslation } from 'react-i18next';

import { DatePicker } from 'antd';

import { monthAgo } from "utils/dateTime";

import { DATE_FORMAT } from "constants/common.constants";

import { getShowTimeOptionValue } from './helpers';

import { TIME_PICKER_MODES } from './constants';

/** DateRangePicker Component */
const DateRangePicker = ({
    showTime,
    timePickerMode = TIME_PICKER_MODES.DEFAULT,
    onChange,
    value,
    enabledMountsCount = 3,
    initialValues={}
}) => {

    const { t } = useTranslation();

    /** This refs are used for disabling dates */
    const _currentFromRef = useRef(null);
    const _currentToRef = useRef(null);

    useEffect(() => {
        _currentFromRef.current = initialValues.from
    }, [initialValues.from])

    useEffect(() => {
        _currentToRef.current = initialValues.to
    }, [initialValues.to])


    const format = showTime ? `${DATE_FORMAT} HH:mm` : DATE_FORMAT;

    const disabledDate = currentDate => {
        if(showTime){
            return !currentDate || currentDate.isBefore(monthAgo(enabledMountsCount)) || currentDate.isAfter(moment().endOf('day'))
        } else {
            if(!currentDate) return false;
            if( currentDate.isAfter(moment().endOf('day'))){
                return true
            }

            if(
                (
                    _currentFromRef.current && currentDate.isSame(_currentFromRef.current, 'day')
                ) || (
                    _currentToRef.current && currentDate.isSame(_currentToRef.current, 'day')
                )
            ){
                return true;
            }

            return false;
        }
    }

    const onCalendarChange = data  => {

        if(data && data[0]){
            _currentFromRef.current = data[0];
        } else {
            _currentFromRef.current = null;
        }

        if(data && data[1]){
            _currentToRef.current = data[1];
        } else {
            _currentToRef.current = null;
        }
    }


    const additionalProps = {}

    if(!showTime){
        additionalProps.onCalendarChange = onCalendarChange;
    }

    return (
        <DatePicker.RangePicker
            className="rt--rangepicker"
            dropdownClassName={"rt--datepicker-popup" + (showTime && timePickerMode === TIME_PICKER_MODES.ONLY_HOURS ? " rt--rangepicker-only-hours" : "")}
            format={format}
            disabledDate={disabledDate}
            suffixIcon={<i className='icon-calendar rt--font-bigest' />}
            separator={<div className='rt--rangepicker-separator rt--ml-8 rt--mr-8' />}
            showTime={getShowTimeOptionValue({ showTime, timePickerMode })}
            allowClear={false}
            onChange={onChange}
            value={value}
            { ...additionalProps }
        />
    )
}

/** DateRangePicker propTypes
    * PropTypes
*/
DateRangePicker.propTypes = {
    /** Show time picker */
    showTime: PropTypes.bool,
    /** On Change function */
    onChange: PropTypes.func,
    /** Value */
    value: PropTypes.array,
    /** Initial Values, used for maxRangeDaysCount validation */
    initialValues: PropTypes.shape({
        from: PropTypes.object,
        to: PropTypes.object
    })
}

export default DateRangePicker;
