import React from 'react';

import { LEADERBOARD_TYPE } from "../constants";

export const getTableColumns = (additionalProps) => {
    const {
        t,
        type,
        currencyCode,
        formatAmount
    } = additionalProps;

    return [
        {
            key: '#',
            title: "#",
            render: (value, rowData, index) => <div className={'rt--chart-top-user-num rt--chart-top-user-num' + '-' + (index + 1)}>
                <span className={'rt--font-normal rt--font-regular'}>
                    {index + 1}
                </span>
            </div>
        },
        {
            title: t("common.id"),
            key: "id",
            copy: true,
        },
        {
            title: t("common.username"),
            key: "userName",
            render: (_, record) =>  <span className='rt--title rt--font-normal rt--font-regular'>{record.userName || record.name}</span>
        },
        {
            title: t("common.amount"),
            key: "amount",
            render: value => <span className='rt--title rt--font-normal rt--font-regular'>{formatAmount(value, currencyCode)}</span>
        },
        {
            title: t("common.count"),
            key: "count",
            isNumeric: true,
        }
    ];
}
