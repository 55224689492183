import React from 'react';

import ManagerDashboardComponent from 'components/manager/dashboard';

import withAuth from 'hocs/withAuth';

const ManagerDashboardRoute = () => {
    return (
        <ManagerDashboardComponent /> 
    )
}

export default withAuth(ManagerDashboardRoute);