import React, { Fragment } from 'react';

import Betshops from "./betshops";
import Filters from "./filters";
import {useTranslation} from "react-i18next";
import {USER_STATE} from "constants/common.constants";
import {Tooltip} from "antd";
import useFormat from "hooks/useFormat";

/** Betshop Manager Betshops Page */
const BetshopManagerBetshops = () => {
    const { t } = useTranslation();
    const { formatAmount } = useFormat();

    /** Table columns */
    const columns = [
        {
            key: "longId",
            title: t("common.id"),
            sorting: true,
            copy: true
        },
        {
            key: "name",
            title: t("common.betshopName"),
            sorting: true
        },
        {
            key: "address",
            title: t("common.location"),
            sorting: true
        },
        {
            key: "state",
            title: "Status",
            render: value => (
                <div className='rt--flex rt--align-center'>
                    <div className='rt--table-marker rt--mr-8' data-type={value === USER_STATE.ACTIVE ? "success" : "error"}></div>
                    <span className='rt--title rt--font-normal rt--font-regular'>{value === USER_STATE.ACTIVE ? t("common.active") : t("common.blocked")}</span>
                </div>
            ),
            sorting: true
        },
        {
            key: "cashiers",
            title: t("common.cashiers"),
            disabled: true,
            render: value => value && value.length > 0 ? (
                <Tooltip
                    placement="bottomLeft"
                    title={(
                        <div className="rt--table-tooltip">
                            {
                                value.map((c, i) => (
                                    <span
                                        key={c.id}
                                        className={'rt--title rt--font-normal rt--font-regular' + (i !== value.length - 1 ? " rt--mb-16" : "")}
                                    >
                                        {
                                            `${c.firstName} ${c.lastName}`
                                        }
                                    </span>
                                ))
                            }
                        </div>
                    )}
                >
                    <span className='rt--title rt--font-normal rt--font-regular'>{`${(value || []).length} ${t("common.cashiers")}`}</span>
                </Tooltip>
            ) : <span className='rt--title rt--font-normal rt--font-regular'>{`${(value || []).length} ${t("common.cashiers")}`}</span>
        },
        {
            key: "balance",
            title: t("common.betshopBalance"),
            render: (value, record) => <span className='rt--title rt--font-normal rt--font-regular'>{formatAmount(value, record.currencyId)}</span>
        },
        {
            key: "limit",
            title: t("common.betshopCurrentLimits"),
            render: (value, record) => <span className='rt--title rt--font-normal rt--font-regular'>{formatAmount(value, record.currencyId)}</span>
        },
        {
            key: "betShopMaxLimit",
            title: t("common.betshopMaxLimits"),
            disabled: true,
            render: (value, record) => <span className='rt--title rt--font-normal rt--font-regular'>{formatAmount(value, record.currencyId)}</span>
        },
        {
            key: "currencyId",
            exportKey: 'CurrencyId',
            title: t("common.currency")
        }
    ];

    return (
        <Fragment>
            <Filters columns={columns} />
            <Betshops columns={columns} />
        </Fragment>
    )
}

export default BetshopManagerBetshops;
