import {useTranslation} from "react-i18next";
import {memo, useMemo, useState} from "react";
import useOperationalPieChart from "components/manager/dashboard/hooks/useOperationalPieChart";
import {getOperationsPieChartOptions} from "utils/dashboard";
import {ENTITY_TYPE} from "components/manager/dashboard/constants";
import {Spin} from "antd";
import HighchartsReact from "highcharts-react-official";
import Highcharts from 'highcharts'
import Loader from "components/common/loader";

const PieChart = ({
    entityType,
    filters,
    updateLastUpdatedDate,
    isActive,
}) => {
    const { t } = useTranslation();

    const [inactiveStates, setInactiveStates] = useState([]);

    const memoFilters = useMemo(() => ({ ...filters }), [filters.filterType]);

    const { chartData, isLoading } = useOperationalPieChart(entityType, memoFilters, updateLastUpdatedDate, isActive);

    const chartOptions = useMemo(() => {
        return getOperationsPieChartOptions(
            chartData,
            entityType,
            inactiveStates,
            (name, isVisible) => {
                if (isVisible) {
                    setInactiveStates(prevState => prevState.filter(s => s !== name))
                } else {
                    setInactiveStates(prevState => prevState.concat([name]))
                }
            }
        );
    }, [entityType, inactiveStates, chartData]);

    const getTitle = (entityType) => {
        const titles = {
            [ENTITY_TYPE.BETSHOP]: t("common.totalBetshops"),
            [ENTITY_TYPE.CASHIER]: t("common.totalCashiers"),
        }

        return titles[entityType] || '';
    }

    return (
        <div className={
            entityType === ENTITY_TYPE.CASHIER
                ?'rt--chart-operational-pie rt--chart-section rt--chart-operational-pie-cashier'
                : entityType === ENTITY_TYPE.PLAYER
                    ? 'rt--chart-operational-pie rt--chart-section rt--chart-operational-pie-player'
                    : entityType === ENTITY_TYPE.BETSHOP
                        ? 'rt--chart-operational-pie rt--chart-section rt--chart-operational-pie-betshop'
                        : 'rt--chart-operational-pie rt--chart-section'
        }>
            <div className="rt--chart-section-title rt--font-extra-bold rt--font-bigest rt--mb-24">
                {getTitle(entityType)}
            </div>
            <div className="rt--chart-operational-pie-block">
                {
                    isLoading
                        ? (
                            <Loader />
                        ) : (
                            <HighchartsReact
                                highcharts={Highcharts}
                                options={chartOptions}
                            />
                        )
                }
            </div>
        </div>
    )
};
export default memo(PieChart)

